import React, { FC, useEffect } from 'react'
import { Alert, Button, Divider, Form, Input, InputNumber, Popover, Select, message } from 'antd'
import { ISettingResponse } from '../../../../services/setting/setting.interface'
import { ISettingSchema } from '../../../../services/setting/setting.schema'
import { COUNTRY_LIST, CURRENCY_LIST, BANK_LIST } from '../../../../config/data.params'
import { useOrderDeliveredEdit, useUserSupSeller } from '../../../../services/order-create/order.edit'
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useTkEmidica } from '../../../../services/setting/setting.service'
import { useCompanyStore } from '../../../../services/company-create/company-create.service'

interface IConfigOrderFormProps {
  initialValues: ISettingResponse | undefined;
  onSubmit: (data: ISettingSchema) => void;
}

const ConfigOrderForm: FC<IConfigOrderFormProps> = ({ initialValues, onSubmit }) => {
  const [form] = Form.useForm<ISettingSchema>()
  const { isUser } = useUserSupSeller()
  const { companyUser } = useOrderDeliveredEdit()
  const { companyGlobal } = useCompanyStore()
  const { generateToken, loadingtk, tk } = useTkEmidica()
  const messageText = 'Por favor es obligatorio completar todos los datos para que '
    .concat('puedan agendar sus pedidos con normalidad y se continuen haciendo los depositos de sus liquidaciones')

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        companyId: initialValues.companyId,
        currencyCode: initialValues.currency?.code,
        tk: initialValues.company.tokenIntegration,
        phone: initialValues.phone,
        countryCode: initialValues.country?.code,
        spreadsheetOrder: {
          spreadSheetId: initialValues.spreadsheetOrder?.spreadSheetId,
          sheetName: initialValues.spreadsheetOrder?.sheetName,
        },
        companyPrefix: initialValues.companyPrefix,
        amountDelivery: initialValues.amountDelivery || 14,
        codeBank: initialValues.codeBank,
        accountNumber: initialValues.accountNumber,
        cci: initialValues.cci,
        holderName: initialValues.holderName,
        businessDocument: initialValues.businessDocument,
        businessName: initialValues.businessName,
        documentHolderAccount: initialValues.documentHolderAccount,
        emailTransfer: initialValues.emailTransfer,
        affiliateCode: `https://aliclik.app/ALICLIK-${initialValues?.companyId}`,
        referenceCode: initialValues.company ? initialValues.company.referenceCode : '',
      })
    }
  }, [initialValues])

  const generateTkEmidica = () => {
    generateToken(companyGlobal ? `${companyGlobal.id}` : '')
  }

  useEffect(() => {
    if (tk) {
      form.setFieldValue(['tk'], tk.tokenIntegration)
      
    }
  }, [tk])

  const handleCopyTkText = () => {
    const linkText = form.getFieldValue('tk')

    if (linkText) {
      
      try {

        navigator.clipboard.writeText(linkText)
        message.success('token copiado')

      } catch (error) {

        message.error('Error al copiar texto')
      }
    }
  }

  const handleCopyText = () => {
    const linkText = form.getFieldValue('affiliateCode')

    if (linkText) {
      
      try {

        navigator.clipboard.writeText(linkText)
        message.success('Link copiado')

      } catch (error) {

        message.error('Error al copiar texto')
      }
    }
  }

  const content = (
    <div>
      <h4>Cuenta soles</h4>
      <p>N° de cuenta: 47505215317055</p>
      <p>CCI: 00247510521531705520</p>
    </div>
  )

  return <>
    <Form
      name="basic"
      initialValues={initialValues}
      onFinish={onSubmit}
      autoComplete="off"
      layout="vertical"
      form={form}
      style={{
        maxWidth: '600px',
      }}
    >

      <Alert message={messageText} type="info" />

      <Divider />

      <Form.Item name={['companyId']} hidden>
        <InputNumber />
      </Form.Item>

      <Form.Item
        label="Prefijo para órdenes"
        name={['companyPrefix']}
      >
        <Input type="text" disabled={companyUser !== 3}/>
      </Form.Item>

      <Form.Item>
        <Form.Item
          label="Link afiliado"
          name={['affiliateCode']}
          style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={' '}
          style={{ display: 'inline-block', width: 'calc(10% - 8px)' }}
        >
          <Button 
            onClick={handleCopyText}
          >
            <CopyOutlined />
          </Button>
        </Form.Item>

      </Form.Item>

      <h3><b>Datos para Negocio</b></h3>

      <Form.Item
        label="Currency"
        name={['currencyCode']}
        required={true}
      >
        <Select
          placeholder="Seleccione una opción"
          allowClear
          options={CURRENCY_LIST.map(e => ({
            label: e.name,
            value: e.code,
          }))}
        />
      </Form.Item>

      <Form.Item>
        <Form.Item
          label="País"
          name={['countryCode']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Select
            placeholder="Seleccione una opción"
            allowClear
            options={COUNTRY_LIST.map(e => ({
              label: e.name,
              value: e.code,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Teléfono"
          name={['phone']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
        >
          <Input type="text" />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          label="Banco"
          name={['codeBank']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Select
            placeholder="Seleccione una entidad bancaria"
            allowClear
            options={BANK_LIST.map(e => ({
              label: `${e.value} / ${e.code}`,
              value: e.code,
            }))}
          />
        </Form.Item>

        <Form.Item
          label={<span>
            <div>
              Número de cuenta <Popover content={content} title='Cuentas'>
                <QuestionCircleOutlined />
              </Popover>
            </div>
          </span>}
          name={['accountNumber']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
        >
          <Input type="text" />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          label={<span>
            <div>
              Número de cuenta interbancaria (CCI) <Popover content={content} title='Cuentas'>
                <QuestionCircleOutlined />
              </Popover>
            </div>
          </span>}
          name={['cci']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Nombre completo del titular"
          name={['holderName']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
        >
          <Input type="text" />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          label="Correo para constancia transferencia"
          name={['emailTransfer']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="RUC/DNI titular de la cuenta"
          name={['documentHolderAccount']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
        >
          <Input type="text" />
        </Form.Item>
      </Form.Item>

      { isUser ? <>
        <Form.Item
          label="Costo de envío para motorizados"
          name={['amountDelivery']}
          hidden
        >
          <Input type="number" />
        </Form.Item>
      </> : <>
        <Form.Item
          label="Costo de envío para motorizados"
          name={['amountDelivery']}
          hidden
        >
          <Input type="number" />
        </Form.Item>
      </>}

      <h3><b>Datos para comprobantes</b></h3>

      <Form.Item>
        <Form.Item
          label="RUC (DNI si no cuentas RUC)"
          name={['businessDocument']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Razón social"
          name={['businessName']}
          required={true}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
        >
          <Input type="text" />
        </Form.Item>
      </Form.Item>

      {/*<Title level={4}>SpreadSheet</Title>*/}

      <Form.Item
        label="SpreadSheet ID"
        name={['spreadsheetOrder', 'spreadSheetId']}
        hidden
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Sheet name"
        name={['spreadsheetOrder', 'sheetName']}
        hidden
      >
        <Input type="text" />
      </Form.Item>

      { initialValues && initialValues.company.tokenIntegration 
        && initialValues.company.tokenIntegration.length > 0 || tk ? <>

          <h3><b>Integración Emidica</b></h3>
          
          <Form.Item>
            <Form.Item
              label="Token Emidica"
              name={['tk']}
              style={{ display: 'inline-block', width: 'calc(90% - 8px)' }}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label={' '}
              style={{ display: 'inline-block', width: 'calc(10% - 8px)' }}
            >
              <Button 
                onClick={handleCopyTkText}
              >
                <CopyOutlined />
              </Button>
            </Form.Item>

          </Form.Item>
        
        </> : <>

          <h3><b>Generar token integración</b></h3>
        
          <Button type='default' shape='round' style={{ backgroundColor: '#000' }} 
            onClick={() => generateTkEmidica()} loading={loadingtk}>
            <img src='https://shop-image.cloud/img/landing/logo.svg' width={120}
              alt='Emidica'></img>
          </Button>
          
        </> }

      <Divider />

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          Guardar
        </Button>
      </Form.Item>
    </Form>
  </>
}

export default ConfigOrderForm
