/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { IOrderPayment } from './order-payment.interface'

export const useOrderListPayment = () => {
  const { execute, response, loading } = useAxios<IOrderPayment[]>()

  const getOrdersPayment = (startDate: string, endDate: string, pay: string,
    countryCode?: string, conveyorId?: string) => {
    execute({
      method: 'GET',
      url: '/order/report/paymentMethod',
      params: {
        paymentMethod: pay === 'ALL' ? '' : pay,
        startDate: startDate,
        endDate: endDate,
        countryCode: countryCode,
        conveyorId: conveyorId === 'ALL' ? '' : conveyorId
      },
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
    })
  }

  return {
    getOrdersPayment,
    loadingPay: loading,
    orders: response || [],
  }
}
