import { Button, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import { useDrawer } from '../../../../../context/DrawerContext'
import { CoveragesListTable } from '../../components'
import { useConveyorStore, useCoverageToTransporter } from '../../../../../services/location/location.service'
import { useCoverageStore } from '../../../../../services/coverage-create/coverage-create.store'
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service'
import { useAuthStore } from '../../../../../services/auth/auth.service'

const { Option } = Select

const CoveragesContainer = () => {
  const { openDrawer } = useDrawer()
  const { getCoverages, coverages, loadingCoverage } = useCoverageToTransporter()
  const { coverageUpdated } = useCoverageStore()
  const { transporters, loadingTransporters, getTransporters } = useTransporterList()
  const [transpSelected, setTranspSelected] = useState<string>()
  const { setConveyorSelected } = useConveyorStore()
  const [hiddenTransp, setHiddenTransp] = useState(false)
  const { authUser } = useAuthStore()

  const handleOpenDrawer = () => {
    //setUserSelected(null)
    openDrawer('COVERAGE_EDIT', 'Agregar cobertura')
  }

  useEffect(() => {
    
    if (authUser.user.role.name === 'SUPER_MASTER') {
      getTransporters()
      setHiddenTransp(false)
    } else if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER') {
      setHiddenTransp(true)
      setTranspSelected(`${authUser.user.conveyor?.id}`)
      getCoverages(`${authUser.user.conveyor?.id}`)
      
      if (authUser.user.conveyor?.id) {
        setConveyorSelected({
          id: authUser.user.conveyor.id,
          name: '',
          isConveyorPremium: false,
          coverages: []
        })
      }
    }

  }, [])

  useEffect(() => {
    if (transporters && transporters.length > 0) {
      getCoverages(`${transporters[0].id}`)
      setTranspSelected(`${transporters[0].name}`)
    }
  }, [transporters])

  useEffect(() => {
    if (coverageUpdated) {
      if (transporters && transporters.length > 0) {

        const t = transporters.find(t => t.name === transpSelected)
  
        if (t) {
          getCoverages(`${t.id}`)
        }

      }

      if (hiddenTransp === true) {
        getCoverages(`${authUser.user.conveyor?.id}`)
      }
    }
  }, [coverageUpdated])
  
  const handleChangeTransporter = (id: string) => {

    if (transporters && transporters.length > 0) {

      const t = transporters.find(t => t.name === id)

      if (t) {

        setConveyorSelected(t)

        getCoverages(`${t.id}`)

        setTranspSelected(id)
      }

    }

  }

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>

          { hiddenTransp ? <></> : <>
            <h5>Transportadora</h5>
            <Select
              onChange={handleChangeTransporter}
              showSearch
              optionFilterProp="key"
              loading={loadingTransporters}
              value={transpSelected}
              style={{ minWidth: '120px' }}
              filterOption={(input, option) =>
                (option?.key as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {transporters?.map(key => (
                <Option
                  key={key.name}
                  value={key.name}
                >
                  {key.name}
                </Option>
              ))}
            </Select>
          </> }

          <Button type="primary" onClick={handleOpenDrawer}>
            Agregar Cobertura
          </Button>
        </div>
      </PageHeader>
      <CoveragesListTable
        coverages={coverages}
        loading={loadingCoverage}
      />
    </>
  )
}

export default CoveragesContainer
