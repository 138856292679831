import React, { FC, useEffect, useState } from 'react'
import { Button, Form, FormInstance, Input, Modal, Space, Typography, message, Radio } from 'antd'
import { IProductSearchSchema } from '../../../../../../services/product/product.schema'
import { ProductListSearch } from '../../../../../OrderModule/DeliveriesView/components'
import { IOrderDetailsResponse } from '../../../../../../services/order-list/order.interface'
import { IOrderUpdateSchema } from '../../../../../../services/order-update/order-update.schema'
import { ProductDeliverySearchForm } from '../../../../OrdersView/forms'
import ProductEditForm from '../../ProductEditForm/ProductEditForm'

const { Title } = Typography

interface IPreOrderProductPartProps {
  form: FormInstance<IOrderUpdateSchema>
  orderDetails: IOrderDetailsResponse[]
  disabled: boolean
  warehouseName: string
  warCompanyId: number
  flagSchedule: boolean
}

const PreOrderProductPart: FC<IPreOrderProductPartProps> = ({
  orderDetails,
  form, disabled,
  warehouseName,
  warCompanyId,
  flagSchedule
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [productExistList, setProductExistList] = useState<IProductSearchSchema[]>([])
  const [total, setTotal] = useState<number>(0)
  const warehouseDpto = Form.useWatch(['shipping', 'dptoJson'], form)
  const warehouseToday = Form.useWatch(['warehouseCurrent'], form)
  const isProductRemnant = Form.useWatch(['isProductRemnant'], form)
  const [productModal, setProductModal] = useState<IProductSearchSchema | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const status = Form.useWatch('status', form)
  const hourMinWarehouse = Form.useWatch(['hoursWork'], form)

  const addDays = (days: number) => {
    form.setFieldValue('addDays', days)
  }

  const hoursWork = (hour: number) => {
    form.setFieldValue('hoursWork', hour)
  }

  const setTypePattern = (pattern: number) => {
    form.setFieldValue('typePattern', pattern)
  }

  const warehouseCurrent = (warName: string) => {
    form.setFieldValue('warehouseCurrent', warName)
  }

  const setDeliveryCost = (deliveryCost: number) => {
    form.setFieldValue('shippingCost', deliveryCost)
  }

  const handleSubmit = (data: IProductSearchSchema) => {
    const prod = productExistList.filter(t => t.action !== 'delete')[0]

    if (prod) {
      if (prod.warehouseId === data.warehouseId) {
        setProductExistList([...productExistList, { ...data, action: 'add' }])
        setIsModalVisible(false)
      } else {
        message.warning(`Debe seleccionar un producto del almacén ${prod.warehouseName}`)
      }
    } else {
      setProductExistList([...productExistList, { ...data, action: 'add' }])
      setIsModalVisible(false)
    }
  }

  const handleDeleteProduct = (item: IProductSearchSchema) => {
    const list: IProductSearchSchema[] = productExistList?.map(e => {
      return e.id === item.id ?
        { ...e, action: 'delete' } :
        e
    })

    setProductExistList(list)
  }

  const handleEditProductShop = (item: IProductSearchSchema) => {
    setOpenModal(true)
    setProductModal(item)
  }

  const onSubmitRecalculateShop = (item: IProductSearchSchema) => {
    
    setProductExistList(productExistList.map(p => {
      if (p.skuId === item.skuId) {
        p.quantityRemnant = (item.quantityRemnant || 0)
        p.price = item.price
        p.subtotal = item.subtotal
        p.quantity = p.quantity - (item.quantityRemnant || 0)
      }

      return p
    }))

    setOpenModal(false)
    setProductModal(null)
    
  }

  useEffect(() => {

    if (productExistList.filter(t => t.action !== 'delete').length > 0) {
      form.setFieldValue('enableDpto', true)
    } else {
      form.setFieldValue('enableDpto', false)
      form.setFieldValue('warehouseCurrent', undefined)
      if (orderDetails.length > 0 && productExistList.length > 0) {
        form.setFieldValue(['shipping', 'dispatchDate'], undefined)
        form.setFieldValue(['shipping', 'scheduleDate'], undefined)
      }
    }

    const prodMap = productExistList.filter(t => t.action !== 'delete')?.map(p => parseFloat(`${p.subtotal}`))

    setTotal(prodMap?.length ? prodMap.reduce((a, b) => a + b) : 0)
    form.setFieldValue('orderDetails', productExistList)
  }, [productExistList])

  useEffect(() => {
    const prodMap: IProductSearchSchema[] = orderDetails?.map(p => {
      const productName = p.sku.product?.name + ' / ' + p.sku.skuOptions
        .map(e => ({
          option: e.option?.name,
          variant: e.option?.variant.name
        }))
        .map(e => `${e.variant} ${e.option}`)
        .join(' / ')

      return {
        id: p.id,
        price: p.price,
        quantity: p.quantity,
        subtotal: p.subtotal,
        skuId: p.sku.id,
        warehouseId: p.warehouseId,
        productName: productName,
        warehouseName: warehouseName,
        warCompanyId: warCompanyId,
        sku: p.sku,
        companyId: p.companyId,
        dropPrice: p.dropPrice,
        quantityRemnant: p.quantityRemnant,
        action: 'update'
      }
    })

    setProductExistList(prodMap)
  }, [orderDetails])

  const hourWarehouse = () => {
    return hourMinWarehouse && hourMinWarehouse.length > 0 ? 
      `Horario de cierre:<br />Contra entrega ${hourMinWarehouse}h` : ''
  }

  return <>
    <Space style={{ marginBottom: '.5rem' }}>
      <Title level={4} style={{ marginBottom: 0 }}>Productos</Title>
      <Button type="link" onClick={() => setIsModalVisible(true)}
        hidden={flagSchedule ? flagSchedule : disabled}>+ Agregar</Button>
      <Title level={5} style={{ marginBottom: 0, fontSize: '12px' }}>
        Almacén: {warehouseToday}<br/>
        <div dangerouslySetInnerHTML={{ __html: hourWarehouse() }} />
        Horario de entrega del pedido de 10AM a 5PM
      </Title>
      <Modal
        title="Buscar Producto"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        <ProductDeliverySearchForm
          warehouseDpto={warehouseDpto}
          onSubmit={handleSubmit}
          addDays={addDays}
          hoursWork={hoursWork}
          warhSelected={warehouseCurrent}
          typePattern={setTypePattern}
          deliveryCost={setDeliveryCost}
        />
      </Modal>
    </Space>

    <ProductListSearch
      data={productExistList.filter(e => e.action !== 'delete')}
      total={total}
      onDelete={handleDeleteProduct}
      onEdit={handleEditProductShop}
      disabled={disabled}
      status={status}
    />

    <Form.Item name={['enableDpto']} label="Activar departamento" hidden>
      <Radio.Group
        options={[
          { label: 'No', value: false },
          { label: 'Si', value: true },
        ]}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>

    <Form.Item name={['addDays']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['hoursWork']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['typePattern']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['warehouseCurrent']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['orderDetails']} hidden>
      <Input />
    </Form.Item>

    <Modal open={openModal} title={productModal?.productName} footer={[]} 
      onCancel={() => {
        setOpenModal(false)
        setProductModal(null)
      }}
      destroyOnClose>
      { productModal ? <>
        <ProductEditForm 
          onSubmit={onSubmitRecalculateShop} 
          product={productModal}
          isProductRemnant={isProductRemnant} />
      </> : null }
    </Modal>

  </>
}

export default PreOrderProductPart
