import React, { FC, useEffect, useState } from 'react'
import { Badge, Button, Divider, Form, Select, Input } from 'antd'
import {
  OrderCustomerPart,
  OrderProductPart,
  OrderShippingPart,
} from './parts'
import { useProductStore } from '../../../../../services/product/product.store'
import TextArea from 'antd/lib/input/TextArea'
import { ORDER_STATUS_LABEL } from '../../../../../services/order-create/order.params'
import {
  ORDER_STATUS_LIST_CONFIRMED,
  ORDER_STATUS_LIST_NOT_AVAILABLE,
} from '../../../../../services/pre-order/pre-order.params'
import { IOrderUpdateSchema } from '../../../../../services/order-update/order-update.schema'
import moment from 'moment'
import { IOrderItemResponse } from '../../../../../services/order-list/order.interface'
import { useSellerList } from '../../../../../services/user-seller-list/user-seller-list.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useOrderConfirmedEdit, useUserSupSeller } from '../../../../../services/order-create/order.edit'
import { ISellerResponse } from '../../../../../services/user-seller-list/user-seller-list.interface'

const { Option, OptGroup } = Select

interface IOrderEditFormProps {
  initialValues: IOrderItemResponse | null
  onSubmit: (data: IOrderUpdateSchema) => void
  disabled: boolean
  required: boolean
}

const OrderEditForm: FC<IOrderEditFormProps> = ({ initialValues, onSubmit, disabled, required }) => {
  const [form] = Form.useForm<IOrderUpdateSchema>()
  const { setProductEmpty } = useProductStore()
  const [used, setUsed] = useState<boolean>(false)
  const [requiredCallStatus, setRequiredCallStatus] = useState<boolean>(false)
  const { getSellers, sellers } = useSellerList()
  const { companyGlobal } = useCompanyStore()
  const seller = Form.useWatch(['user', 'fullname'], form)
  const callStatus = Form.useWatch('callStatus', form)
  const { companyUser } = useOrderConfirmedEdit()
  const { isUser } = useUserSupSeller()
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form)
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form)
  const districtId = Form.useWatch(['shipping', 'districtId'], form)
  const transportId = Form.useWatch(['transportId'], form)

  useEffect(() => {
    if (companyUser === 2) {
      if (callStatus === 'CONFIRMED') {
        setRequiredCallStatus(true)
      } else {
        setRequiredCallStatus(false)
      }
    }
  }, [callStatus])

  useEffect(() => {
    if (initialValues === null) {
      form.setFieldValue(['shipping', 'shippingByAgency'], false)
      setRequiredCallStatus(true)
      if (!disabled) {
        setUsed(true) 
      } else setUsed(false)
    } else {
      setUsed(disabled)
      
      if (isUser) {
        getSellers(companyGlobal ? `${companyGlobal.id}` : '')
      }
    }

    if (initialValues) {
      setProductEmpty()

      const shipping = initialValues.shipping

      form.setFieldsValue({
        status: initialValues.status,
        callStatus: initialValues.callStatus,
        transportId: initialValues.transporterId,
        note: initialValues.note,
        channel: initialValues.channel,
        shippingCost: initialValues.shippingCost,
        orderRelated: initialValues.orderRelated,
        user: {
          id: initialValues.user.id,
          fullname: initialValues.user.fullname,
        },
        customer: {
          name: initialValues.customerHistory?.name,
          lastName: initialValues.customerHistory?.lastName || '',
          phone: initialValues.customerHistory?.phone,
          companyId: initialValues.customerHistory?.companyId,
        },
        shipping: {
          id: shipping.id,
          address1: shipping?.address1,
          address2: shipping?.address2 || '',
          reference: shipping?.reference,
          shippingByAgency: initialValues.isOrderAgency,
          gps: (shipping?.lat.length > 0 && shipping?.lng.length > 0) ? `${shipping?.lat},${shipping?.lng}` : '',
          department: shipping?.departmentName,
          departmentId: Number.parseInt(shipping?.departmentCode),
          province: shipping?.provinceName,
          provinceId: Number.parseInt(shipping?.provinceCode),
          district: shipping?.districtName,
          districtId: Number.parseInt(shipping?.districtCode),
          contactName: shipping?.contactName,
          contactPhone: shipping?.contactPhone,
          contactDocumentNumber: shipping?.contactDocumentNumber,
          contactDocumenType: shipping?.contactDocumenType,
          guideNumber: shipping?.guideNumber,
          attachFile: shipping?.attachFile,
          addressPickUp: shipping?.addressPickUp,
          shippingCost: shipping?.shippingCost,
          keyCode: shipping?.keyCode,
          agencyName: shipping?.agencyName,
          scheduleDate: shipping?.scheduleDate ? moment(new Date(shipping.scheduleDate), 'DD/MM/YYYY') : '',
          dispatchDate: shipping?.dispatchDate ? moment(new Date(shipping.dispatchDate), 'DD/MM/YYYY') : '',
        }
      })

      if (initialValues.status === null) {
        form.setFieldValue('status', 'PENDING_DELIVERY')
      }

    } else {
      form.setFieldValue('status', 'PENDING_DELIVERY')
      form.setFieldValue('callStatus', 'CONFIRMED')
    }
  }, [initialValues])

  useEffect(() => {
    if (seller && sellers.length > 0) {
      const s = sellers.find((i: ISellerResponse) => i.fullname == seller)

      form.setFieldValue(['user', 'id'], s?.id)
    }
  }, [seller, sellers])

  return <>
    <Form
      layout="horizontal"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      form={form}
      name="order"
      labelWrap
      onFinish={onSubmit}
    >

      {
        initialValues ? <>
          <Form.Item name="callStatus" label="Estado llamada" 
            rules={[{ required: true, message: '' }]}>
            <Select placeholder="Seleccione una opción" disabled={used}>
              {ORDER_STATUS_LIST_CONFIRMED.map(status => (
                <Option key={status.value} value={status.value}>{status.label}</Option>
              ))}
              <OptGroup label="ORDEN">
                {ORDER_STATUS_LIST_NOT_AVAILABLE.map(status => (
                  <Option key={status.value} value={status.value}>{status.label}</Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item name="status" hidden={true}>
            <Input />
          </Form.Item>
        </> : <>
          <Form.Item name="status" label="Estado" 
            rules={[{ required: true, message: '' }]}>
            <Select placeholder="Seleccione una opción" disabled={used}>
              {Object.keys(ORDER_STATUS_LABEL).map(key => (
                <Option
                  key={key}
                  value={key}
                >
                  <Badge style={{ marginRight: '.5rem' }} color={ORDER_STATUS_LABEL[key].color} text="" />
                  {ORDER_STATUS_LABEL[key].label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="callStatus" hidden={true}>
            <Input />
          </Form.Item>
        </>
      }

      <Form.Item label="Nota" name="note">
        <TextArea rows={1} disabled={disabled}/>
      </Form.Item>

      <Form.Item label="Costo de entrega" name="shippingCost" hidden={!isUser}>
        <Input disabled={disabled ? disabled : (!initialValues?.isWarehouseMain)}/>
      </Form.Item>

      <Form.Item name="channel" label="Canal" rules={[{ required: true, message: '' }]}>
        <Select
          disabled={!!initialValues}
          placeholder="Seleccione una opción">
          <Option value="Shopify">Shopify</Option>
          <Option value="Whatsapp">Whatsapp</Option>
          <Option value="Llamada">Llamada</Option>
          <Option value="Redes sociales">Redes sociales</Option>
        </Select>
      </Form.Item>

      <Form.Item name={['user', 'id']} hidden={true}>
        <Input />
      </Form.Item> 

      { companyUser === 3 ? <>
        <Form.Item label="Número de pedido" name="orderRelated">
          <Input/>
        </Form.Item>
      </> : null }

      { initialValues && isUser ? <>
        <Form.Item name={['user', 'fullname']} label="Vendedor">
          <Select placeholder="Seleccione un vendedor">
            { sellers.map((s: ISellerResponse) => (
              <Option key={s.id} value={s.fullname}>
                { s.fullname }
              </Option>
            ))}
          </Select>
        </Form.Item>
      </> : <>
        <Form.Item name={['user', 'fullname']} hidden={true}>
          <Input />
        </Form.Item>
      </> }

      <Divider />

      <OrderCustomerPart
        disabled={disabled}
      />

      <Divider />

      <OrderShippingPart
        form={form}
        disabled={disabled}
        required={required}
        requiredCallStatus={requiredCallStatus}
        warName={initialValues?.warehouseName || ''}
        deliveryDate={initialValues?.shipping?.scheduleDate || undefined}
        dispatchDateCurrent={initialValues?.shipping?.dispatchDate || undefined}
      />
      
      { departmentId && provinceId && districtId && transportId ? <>

        <Divider />
        <OrderProductPart
          form={form}
          orderDetails={initialValues?.orderDetails}
          disabled={disabled}
          warehouseName={initialValues?.warehouseName || ''}
          warCompanyId={initialValues?.warehouseHistory?.companyId || -1}
        /> </> : null }

      <Divider />

      { /*initialValues ? <>
        <OrderAgencyPart form={form} disabled={disabled}/>
        <Divider />
      </> : null */}
      
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" hidden={disabled}>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  </>
}

export default OrderEditForm
