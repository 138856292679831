import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import CoverageByUserTable from './parts/CoverageByUserTable'
import { ICoverageConveyor } from '../../../../../services/location/location.interface'

interface ICoverageListTableProps {
  coverages: ICoverageConveyor[]
  loading: boolean
}

const CoveragesListTable: React.FC<ICoverageListTableProps> = ({ coverages, loading }) => {

  const columns: ColumnsType<ICoverageConveyor> = [
    {
      title: 'Ciudad',
      dataIndex: 'name',
      width: '80%',
      render: (_, coverage) => <>
        {coverage.name}
      </>
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={coverages}
      columns={columns}
      rowClassName="editable-row"
      loading={loading}
      size="small"
      expandable={{
        expandedRowRender: (record) => <CoverageByUserTable record={record} />,
        rowExpandable: record => !!record.transporters?.length,
      }}
    />
  )
}

export default CoveragesListTable

