import { useAxios } from '../../hooks'
import { IAuthUserResponse, IUserResponse } from '../auth/auth.interface'
import { useAuthStore } from '../auth/auth.service'
import { useCompanyStore } from '../company-create/company-create.service'

export const useUsersPagination = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading } = useAxios<IUserResponse>()

  const url = authUser.user.role.name === 'STORE' ? '/maintenance/user/store/all' : '/maintenance/user/all'

  const getUsers = (
    isActive: boolean,
    search?: string,
    page?: string,
    companyId?: string
  ) => {

    execute({
      method: 'GET',
      url: url,
      params: {
        companyId: companyId === 'ALL' ? '' : companyId,
        search: search ? search : '',
        isActive: isActive,
        page: page ? page : '',
        countryCode: companyGlobal ? companyGlobal.countryCode : authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
        warehouseId: authUser.user.warehouse?.id
      }
    })

  }

  return {
    getUsers,
    count: response ? response.count : 0,
    page: response ? response.page : 1,
    loading: loading,
    response: response?.result ? response.result : []
  }

}

export const useUsersList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IAuthUserResponse[]>()

  const getUsersByActived = (isActive: boolean, companyId?: string, search?: string) => {
    execute({
      method: 'GET',
      url: '/maintenance/user',
      params: {
        companyId: companyId || authUser.user.company.id,
        isActive: isActive,
        search: search ? search : ''
      },
    })
  }

  const getUsers = (companyId?: string) => {
    execute({
      method: 'GET',
      url: '/maintenance/user',
      params: {
        companyId: companyId || authUser.user.company.id,
      },
    })
  }

  const getMotorized = (companyId: number) => {
    execute({
      method: 'GET',
      url: '/maintenance/user',
      params: {
        companyId,
        companyType: 'PRINCIPAL',
      },
    })
  }

  const getStores = (companyId?: string) => {
    execute({
      method: 'GET',
      url: '/maintenance/user/store',
      params: {
        companyId: companyId || authUser.user.company.id
      }
    })
  }

  return {
    getUsers,
    getMotorized,
    getStores,
    loading,
    users: response ? response : [],
    getUsersByActived,
  }
}
