import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { useUserCreate } from '../user-create/user.edit'
import { IRoleItemResponse } from './role-list.interface'

export const useRolesList = (isStore: boolean) => {
  const { userId } = useUserCreate()
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IRoleItemResponse[]>()

  const getRoles = () => {
    execute({
      method: 'GET',
      url: '/maintenance/role',
    })
  }

  let rolesList: IRoleItemResponse[] = []

  if (isStore === true) {

    if (authUser.user.role.name === 'STORE') {
      rolesList = response
        ?.filter(r => r.name === 'STORE') || []
    } else {
      rolesList = response
        ?.filter(r => r.name === 'ADMIN_STORE' || r.name === 'SELLER' || r.name === 'STORE') || []
    }

  } else {

    if (userId === 1) {
      rolesList = response
        ?.filter(r => r.name === 'ADMIN' || r.name === 'MASTER' || r.name === 'MOTORIZED' ||
          r.name === 'TRANSPORT' || r.name === 'STORE_CENTRAL'
        ) || []
    } else if (userId === 2) {
      rolesList = response
        ?.filter(r => r.name === 'ADMIN' || r.name === 'MASTER' || r.name === 'MOTORIZED' ||
          r.name === 'TRANSPORT' || r.name === 'STORE_CENTRAL'
        ) || []
    } else if (userId === 3) {
      rolesList = response
        ?.filter(r => r.name === 'ADMIN' || r.name === 'MOTORIZED' ||
          r.name === 'TRANSPORT' || r.name === 'STORE_CENTRAL'
        ) || []
    } else {
      rolesList = []
    }

  }

  return {
    getRoles,
    loading,
    roles: rolesList || []
  }
}
