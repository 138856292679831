import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { useCompanyStore } from '../company-create/company-create.service'
import { IDispatchGuideBaseResponse, IGuideOrderSummary, IOrderDetailGuide } from './dispatch-guide-list.interface'

export const useDispatchGuideList = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading } = useAxios<IDispatchGuideBaseResponse>()

  const getDispatchGuides = (page?: number, search?: string, warehouseId?: string, transpId?: string) => {
    execute({
      method: 'GET',
      url: '/dispatch-guide/all',
      params: {
        companyId: companyGlobal ? companyGlobal.id === 0 ? '' : companyGlobal.id : authUser.user.company.id,
        warehouseId: warehouseId ? warehouseId === 'ALL' ? '' : warehouseId : 
          authUser.user.warehouse ? authUser.user.warehouse.id : '',
        page: page ? page : '',
        search: search ? search : '',
        conveyorId: transpId === 'ALL' ? '' : transpId
      },
    })
  }

  return {
    getDispatchGuides,
    loading,
    dispatchGuides: response ? response?.result : [],
    count: response ? response?.count : 0,
    currentPage: response ? response?.page : 1,
  }
}

export const useOrdersByGuide = () => {
  const { execute, response, loading } = useAxios<IGuideOrderSummary[]>()

  const getOrdersGuide = (id: number, dispatchStatus?: string) => {
    execute({
      method: 'GET',
      url: `/dispatch-guide/orders/summary/${id}`,
      params: {
        dispatchStatus: dispatchStatus,
      },
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com'
    })
  }

  return {
    getOrdersGuide,
    loadingOrders: loading,
    orders: response ? response : [],
  }
}

export const useOrderDetailsByGuide = () => {
  const { execute, response, loading } = useAxios<IOrderDetailGuide[]>()

  const getOrderDetails = (id: number) => {
    execute({
      method: 'GET',
      url: `/order/summary-products/${id}`,
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
    })
  }

  return {
    getOrderDetails,
    loadingOrderDetails: loading,
    orderDetails: response
  }
}
