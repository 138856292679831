import moment from 'moment'
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IOrderDeliveryFilter, IOrderDeliveryItemResponse } from '../order-motorized/order-motorized.interface'
import { CALL_STATUS_LIST } from '../pre-order/pre-order.params'

export const useOrderMapList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IOrderDeliveryItemResponse[]>()
  
  const getOrdersMap = (filter?: IOrderDeliveryFilter) => {
    execute({
      method: 'GET',
      url: '/order/motorized/map',
      params: {
        status: 'PENDING_DELIVERY',
        callStatus: CALL_STATUS_LIST.CONFIRMED,
        companyId: filter?.companyId || authUser.user.company.id,
        createdAtFrom: filter?.createdAtFrom || moment().endOf('day').toJSON(),
        createdAtTo: filter?.createdAtTo || moment().startOf('day').toJSON(),
        provinceCode: filter?.provinceCode === 'ALL' ? '' : filter?.provinceCode,
        isOrderAgency: filter?.isOrderAgency === 'FALSE' ? '0' : '1',
        countryCode: authUser.user.company.countryCode,
        conveyorId: filter?.conveyorId === 'ALL' ? '' : filter?.conveyorId
      },
    })
  }
  
  return {
    getOrdersMap,
    loadingMap: loading,
    ordersMap: response ? response : [],
  }
}
