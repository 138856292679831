import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { useCompanyStore } from '../company-create/company-create.service'
import { IUserCreateRequest, IUserCreateResponse } from './user-create.interface'
import { mapToUserCreateRequest, mapToUserTransporterCreateRequest } from './user-create.mapper'
import { IUserCreateSchema } from './user-create.schema'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'
import { IAuthUserResponse } from '../auth/auth.interface'

export const useDeleteUser = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IAuthUserResponse>() 

  const deleteUser = (userId: number) => {

    const action = 'USER_DELETE'

    datadogLogs.logger.info(action, { action: action,
      request: {
        userId: userId,
      },
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
    })
    
    execute({
      method: 'PATCH',
      url: `/maintenance/user/delete/${userId}`,
    })
  
  }

  return {
    deleteUser,
    userDeleted: response,
    loadingDeleted: loading
  }
  
}

export const useCreateUser = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading } = useAxios<IUserCreateResponse>()

  const createUser = (data: IUserCreateSchema) => {
    const request: IUserCreateRequest = mapToUserCreateRequest(data, authUser, companyGlobal?.id)
    const method = data.id ? 'PATCH' : 'POST'
    const url = data.id ? `/maintenance/user/${data.id}` : '/user'

    const action = data.id ? 'USER_UPDATE' : 'USER_CREATE'

    datadogLogs.logger.info(action, { action: action,
      request: request,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
      email: request.email,
      fullName: request.fullname,
      role: request.roleName
    })

    execute({
      method,
      url,
      data: request,
    })
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('USER:POST', { action: 'USER:POST',
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        userResponse: response,
      })
    }
  }, [response])

  return {
    createUser,
    userCreated: response,
    loading,
  }
}

export const useCreateUserToTransporter = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IUserCreateResponse>()

  const createUser = (data: IUserCreateSchema) => {
    const request: IUserCreateRequest = mapToUserTransporterCreateRequest(data, authUser)
    const method = data.id ? 'PATCH' : 'POST'
    const url = data.id ? `/maintenance/user/transporter/${data.id}` : '/user/transporter'

    const action = data.id ? 'USER_TRANSPORTER_UPDATE' : 'USER_TRANSPORTER_CREATE'

    datadogLogs.logger.info(action, { action: action,
      request: request,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
      email: request.email,
      fullName: request.fullname,
      role: request.roleName
    })

    execute({
      method,
      url,
      data: request,
    })
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('USER_TRANSPORTER:POST', { action: 'USER_TRANSPORTER:POST',
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        userResponse: response,
      })
    }
  }, [response])

  return {
    createUser,
    userCreated: response,
    loading,
  }
}

