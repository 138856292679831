import { Badge, Button, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useUserStore } from '../../../../../services/user/user.service'
import { UsersListTable } from '../../components'
import { 
  useTransporterList,
  useUserToTransporterList 
} from '../../../../../services/transporter-list/transporter-list.service'
import { useAuthStore } from '../../../../../services/auth/auth.service'

const { Search } = Input
const { Option } = Select

const UsersTransporterContainer = () => {
  const { openDrawer } = useDrawer()
  const { authUser } = useAuthStore()
  const { getUsersToTransporter, users, loading } = useUserToTransporterList()
  const { userUpdated, setUserSelected } = useUserStore()
  const [searchText, setSearchText] = useState<string>('')
  const { transporters, loadingTransporters, getTransporters } = useTransporterList()
  const [transpSelected, setTranspSelected] = useState<string>('ALL')
  const [hiddenTransp, setHiddenTransp] = useState(false)

  const handleOpenDrawer = () => {
    setUserSelected(null)
    openDrawer('USER_TRANSPORTER_EDIT', 'Agregar usuario')
  }

  useEffect(() => {

    if (authUser.user.role.name === 'SUPER_MASTER') {
      getTransporters()
      setHiddenTransp(false)
      getUsersToTransporter(searchText, transpSelected)
    } else if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER') {
      setHiddenTransp(true)
      setTranspSelected(`${authUser.user.conveyor?.id}`)
      getUsersToTransporter(searchText, `${authUser.user.conveyor?.id}`)
    }

  }, [])

  const onSearch = (value: string) => {
    getUsersToTransporter(value, transpSelected)

    setSearchText(value)
  
  }

  useEffect(() => {
    if (userUpdated) {
      
      getUsersToTransporter(searchText, transpSelected)
      
    }
  }, [userUpdated])

  const handleChangeTransporter = (id: string) => {

    getUsersToTransporter(searchText, id)
    setTranspSelected(id)
  }

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>

          { hiddenTransp ? <></> : <>
            <h5>Transportadora</h5>
            <Select
              onChange={handleChangeTransporter}
              showSearch
              optionFilterProp="key"
              loading={loadingTransporters}
              value={transpSelected}
              style={{ minWidth: '120px' }}
              filterOption={(input, option) =>
                (option?.key as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option key='ALL' value='ALL'>
                <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                  TODOS
                </Badge>
              </Option>
              {transporters?.map(key => (
                <Option
                  key={key.name}
                  value={key.id}
                >
                  {key.name}
                </Option>
              ))}
            </Select>
          </>
          }

          <Button type="primary" onClick={handleOpenDrawer}>
            Agregar usuario
          </Button>

          <Search placeholder="Buscar usuarios"
            onSearch={onSearch} enterButton style={{ width: 304 }} allowClear/>
          <div>|</div>

        </div>
      </PageHeader>

      <UsersListTable
        users={users}
        loading={loading}/>

    </>
  )
}

export default UsersTransporterContainer
