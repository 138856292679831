import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, Form, InputNumber, Radio, Select } from 'antd'
import { useLocationLevel1 } from '../../../../../services/location/location.service'
import { ICoverageCreateSchema } from '../../../../../services/coverage-create/coverage-create.schema'
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service'

interface ICoverageEditFormProps {
  onSubmit: (data: ICoverageCreateSchema) => void;
}

const CoverageEditForm: FC<ICoverageEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ICoverageCreateSchema>()
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } = useLocationLevel1()
  const { getWarehouses, warehouses, loadingWarehouses } = useWarehouseByCountry()
  const warehouseId = Form.useWatch(['warehouse'], form)
  const [ warSelected, setWarSelected] = useState<any>(null)

  const handleSubmit = (data: ICoverageCreateSchema) => {
    
    onSubmit(data)
  }

  useEffect(() => {
    if (warehouseId && warehouses.length > 0) {

      const war = warehouses.find(w => `${w.id}` === `${warehouseId}`) 

      if (war) {
        console.log(JSON.stringify(war))
        setWarSelected(war)
      }
    }
  }, [warehouseId])

  useEffect(() => {
    getLocationsGeoLevel1()
    getWarehouses()

    form.setFieldValue('flagDeadLineTime', 0)
  }, [])

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17 }}
      form={form}
      name="order"
      labelWrap
      onFinish={handleSubmit}
    >

      <Form.Item
        label="Departamento"
        name={['department']}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          showSearch
          loading={loadingGeo1}
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={locationsGeolevel1?.map(e => ({
            label: e.name,
            value: e.id,
          }))}
        />
      </Form.Item>

      <Form.Item
        label="Almacen"
        name={['warehouse']}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          showSearch
          allowClear
          loading={loadingWarehouses}
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={warehouses?.map(e => ({
            label: e.name,
            value: e.id,
          }))}
        />
      </Form.Item>

      <Form.Item
        label='Dias disponible'
        name={['addDays']}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de entrega'
        name={['deliveryCost']}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de devolución'
        name={['returnCost']}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de entrega premium'
        name={['deliveryCostPremium']}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de devolución premium'
        name={['returnCostPremium']}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de motorizado'
        name={['motorizedCost']}
      >
        <InputNumber />
      </Form.Item>

      { warSelected !== null ? <>

        <Form.Item
          label="Hora de cierre"
          name={['flagDeadLineTime']}
          rules={[{ required: true, message: '' }]}>
          
          <Radio.Group>
            <Radio value={1}>Principal {warSelected.deadlineTimeDelivery || 0}h</Radio>
            <Radio value={0}>Secundario {warSelected.deadLineTimeSecundary || 0}h</Radio>
          </Radio.Group>
        </Form.Item>

      </> : <></>}

      <Divider />

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CoverageEditForm
