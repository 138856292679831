import React, { useEffect, useState } from 'react'
import { Badge, Button, DatePicker, Divider, Select, Typography } from 'antd'
import moment from 'moment'
import { RangePickerProps } from 'antd/lib/date-picker'
import { useProductRemainingList } from '../../../../services/report-remaining-products/report-remaining-prod.service'
import { downloadExcel } from 'react-export-table-to-excel'
import { useAuthStore } from '../../../../services/auth/auth.service'
import { useTransporterList } from '../../../../services/transporter-list/transporter-list.service'

const { Title } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const ProductRemainingReport = () => {
  const { authUser } = useAuthStore()
  const dateFormat = 'YYYY-MM-DD'
  const { getProductsRemaining, loadingProduct, products } = useProductRemainingList()
  const [selectedDates, setSelectedDates] = useState([
    moment(new Date(), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])
  const headerProduct = [
    'NRO. PEDIDO', 'TRANSPORTADORA', 'PRODUCTO', 'ESTADO ENTREGA', 'MOTORIZADO', 'FECHA REPROGRAMADA', 'ALMACÉN',
    'FECHA ENTREGA', 'ESTADO DESPACHO', 'ÚLTIMO ESTADO ENTREGA'
  ]
  const { transporters, loadingTransporters, getTransporters } = useTransporterList()
  const [transpSelected, setTranspSelected] = useState<string>('ALL')
  const [hiddenTransp, setHiddenTransp] = useState(false)
  const [wareSelected, setWareSelected] = useState<string>('')

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > moment().startOf('day').add(1, 'day')
  }

  function handleDownloadReportProductsExcel() {
    downloadExcel({
      fileName: `report-product-${selectedDates[0].format('yyyy-MM-DD')}-to-${selectedDates[1].format('yyyy-MM-DD')}`,
      sheet: 'react-export-table-to-excel',
      tablePayload: {
        header: headerProduct,
        body: products.map(p => {
          return { 
            orderNumber: p.orderNumber,
            transporter: p.transporter,
            productName: p.productName,
            status: p.status,
            motorized: p.motorized,
            rescheduledDate: p.rescheduledDate,
            warehouse: p.warehouse,
            deliveryDate: p.deliveryDate,
            dispatchStatus: p.dispatchStatus,
            lastRescheduledStatus: p.lastRescheduledStatus,
          }
        })
      }
    })
  }

  const handleCalendarChange = (dates: any, dateStrings: any) => {
    setSelectedDates(dates)
  }

  const handleChangeTransporter = (id: string) => {
    setTranspSelected(id)
  }

  useEffect(() => {
    if (selectedDates.length === 2 && selectedDates[0] && selectedDates[1]) {
      getProductsRemaining(
        selectedDates[0].format('yyyy-MM-DD'),
        selectedDates[1].format('yyyy-MM-DD'),
        transpSelected,
        wareSelected
      )
    }
  }, [selectedDates])

  useEffect (() => {
    if (authUser.user.role.name === 'SUPER_MASTER') {
      getTransporters()
      setHiddenTransp(false)
    } else if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' ||
      authUser.user.role.name === 'STORE'
    ) {
      setHiddenTransp(true)
      setTranspSelected(`${authUser.user.conveyor?.id}`)

      if (authUser.user.role.name === 'STORE') {
        setWareSelected(`${authUser.user.warehouse?.id}`)
      } else {
        setWareSelected('')
      }
    }
  }, [])

  return <>
    <div>

      <Divider />
      
      <Title level={5} style={{ marginBottom: '0', alignContent: 'center', padding: '.85rem 1.5rem' }}>
        Reporte de remanentes
      </Title>

      <div
        style={{
          padding: '.25rem 1.5rem', display: 'flex',
          gap: '1rem', alignItems: 'center',
        }}
      >

        { hiddenTransp ? <></> : <>
          <Title level={5} style={{ marginBottom: '0' }}>
            Transportadora
          </Title>
          <Select
            onChange={handleChangeTransporter}
            showSearch
            optionFilterProp="key"
            loading={loadingTransporters}
            value={transpSelected}
            style={{ minWidth: '120px' }}
            filterOption={(input, option) =>
              (option?.key as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key='ALL' value='ALL'>
              <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                TODOS
              </Badge>
            </Option>
            {transporters?.map(key => (
              <Option
                key={key.name}
                value={key.id}
              >
                {key.name}
              </Option>
            ))}
          </Select>
        </> }

        <Title level={5} style={{ marginBottom: '0' }}>
          Seleccionar fechas
        </Title>

        <RangePicker format={dateFormat}
          disabledDate={disabledDate}
          defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
          onCalendarChange={handleCalendarChange} clearIcon={false}/>

        <Button type='primary' onClick={handleDownloadReportProductsExcel} 
          loading={loadingProduct} disabled={products.length === 0}>
            Descargar reporte
        </Button>

      </div>

    </div>

  </>
}

export default ProductRemainingReport
