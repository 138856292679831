import { Select, DatePicker, DatePickerProps, Button, Badge } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { PageHeader } from '../../../../../components'
import { DepositTable } from '../../components'
import {
  useLiquidationList,
  useLiquidationTotal
} from '../../../../../services/liquidation-list/liquidation-list.service'
import { useMotorizedToConveyorList } from '../../../../../services/user-motorized/user-motorized.service'
import { IMotorizedResponse } from '../../../../../services/user-motorized/user-motorized.interface'
import { LIQUIDATION_STATUS_LABEL } from '../../../../../services/liquidation-list/liquidation.params'
import { useLiquidationStore } from '../../../../../services/liquidation-edit/liquidation-edit.store'
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service'
import { useAuthStore } from '../../../../../services/auth/auth.service'

const { Option } = Select

const DepositListContainer = () => {
  const { getLiquidations, liquidations, loading } = useLiquidationList()
  const { getLiquidationTotal, summaryTotal } = useLiquidationTotal()
  const { getMotorizeds, motorizeds, loadingMt } = useMotorizedToConveyorList()
  const [motorizedSelected, setMotorizedSelected] = useState<number>()
  const [statusSelected, setStatusSelected] = useState<string>()
  const [dateSelected, setDateSelected] = useState<string>()
  const { liquidationUpdated, liquidationCalculated } = useLiquidationStore()
  const { transporters, loadingTransporters, getTransporters } = useTransporterList()
  const [transpSelected, setTranspSelected] = useState<string>('ALL')
  const [hiddenTransp, setHiddenTransp] = useState(false)
  const { authUser } = useAuthStore()

  useEffect(() => {

    if (authUser.user.role.name === 'SUPER_MASTER') {
      getTransporters()
      setHiddenTransp(false)
      setDateSelected(moment().startOf('day').format('yyyy-MM-DD'))
    } else if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER') {
      setHiddenTransp(true)
      setTranspSelected(`${authUser.user.conveyor?.id}`)
      getMotorizeds(`${authUser.user.conveyor?.id}`)

      getLiquidations({
        deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        userId: motorizedSelected ? String(motorizedSelected) : '',
        status: statusSelected ? statusSelected : '',
        conveyorId: `${authUser.user.conveyor?.id}`
      })

      getLiquidationTotal(
        dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        `${authUser.user.conveyor?.id}`
      )

      setDateSelected(moment().startOf('day').format('yyyy-MM-DD'))
    }
    
  }, [])

  useEffect(() => {
    if (transporters && transporters.length > 0) {
      getMotorizeds(transpSelected)

      getLiquidations({
        deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        userId: motorizedSelected ? String(motorizedSelected) : '',
        status: statusSelected ? statusSelected : '',
        conveyorId: transpSelected
      })

      getLiquidationTotal(
        dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        transpSelected
      )
    }
  }, [transporters])

  const handleChangeTransporter = (id: string) => {

    getLiquidations({
      deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
      userId: motorizedSelected ? String(motorizedSelected) : '',
      status: statusSelected ? statusSelected : '',
      conveyorId: id
    })

    getLiquidationTotal(
      dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
      id
    )

    getMotorizeds(id)
    setTranspSelected(id)
  }

  const handleMotorizedChange = (value: number, _: any) => {
    setMotorizedSelected(value)
  }

  const handleStatusChange = (value: string, _: any) => {
    setStatusSelected(value)
  }

  const handleDateChange: DatePickerProps['onChange'] = (date) => {
    setDateSelected(date?.startOf('day').format('yyyy-MM-DD'))
  }

  useEffect(() => {
    if (liquidationUpdated) {
      getLiquidations({
        deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        userId: motorizedSelected ? String(motorizedSelected) : '',
        status: statusSelected ? statusSelected : '',
        conveyorId: transpSelected
      })

      getLiquidationTotal(
        dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        transpSelected
      )
    }
  }, [liquidationUpdated])

  useEffect(() => {
    if (liquidationCalculated) {
      getLiquidations({
        deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        userId: motorizedSelected ? String(motorizedSelected) : '',
        status: statusSelected ? statusSelected : '',
        conveyorId: transpSelected
      })

      getLiquidationTotal(
        dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        transpSelected
      )

    }
  }, [liquidationCalculated])

  const handleChange = () => {
    getLiquidations({
      deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
      userId: motorizedSelected ? String(motorizedSelected) : '',
      status: statusSelected ? statusSelected : '',
      conveyorId: transpSelected
    })

    getLiquidationTotal(
      dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
      transpSelected
    )
  }

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex', justifyContent: 'space-between', gap: '.5rem', alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>

            { hiddenTransp ? <></> : <>
            
              <h5>Transportadora</h5>
              <Select
                onChange={handleChangeTransporter}
                showSearch
                optionFilterProp="key"
                loading={loadingTransporters}
                value={transpSelected}
                style={{ minWidth: '120px' }}
                filterOption={(input, option) =>
                  (option?.key as string).toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option key='ALL' value='ALL'>
                  <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                    TODOS
                  </Badge>
                </Option>
                {transporters?.map(key => (
                  <Option
                    key={key.name}
                    value={key.id}
                  >
                    {key.name}
                  </Option>
                ))}
              </Select>
              
            </>}

            <DatePicker
              placeholder='Seleccionar día'
              onChange={handleDateChange}
              defaultValue={moment()}
              format={'yyyy-MM-DD'}
            />
            <div> | </div>
            <Select
              loading={loadingMt}
              style={{ width: '200px' }}
              showSearch
              optionFilterProp="label"
              allowClear
              placeholder='Seleccionar motorizado'
              options={motorizeds.map((e: IMotorizedResponse) => ({
                label: e.email,
                value: e.id,
              }))}
              onChange={handleMotorizedChange}
            />
            <div> | </div>
            <Select
              style={{ width: '200px' }}
              showSearch
              allowClear
              optionFilterProp="label"
              placeholder='Seleccionar estado'
              onChange={handleStatusChange}
            >
              {Object.keys(LIQUIDATION_STATUS_LABEL).map(key => (
                <Option key={key} value={key}>
                  {LIQUIDATION_STATUS_LABEL[key].label}
                </Option>
              ))}
            </Select>
            
            <div> | </div>

            <Button type='primary' onClick={() => handleChange()}>Buscar</Button>

            <div> | </div>
            
            <h5>#Entregados: {summaryTotal ? summaryTotal.orderDelivered.toFixed(0) : 0}</h5>

            <div> | </div>

            <h5>E: {summaryTotal ? summaryTotal.totalCash.toFixed(0) : 0}</h5>

            <div> | </div>

            <h5>T: {summaryTotal ? summaryTotal.totalTransfer.toFixed(0) : 0}</h5>

            <div> | </div>

            <h5>POS: {summaryTotal ? summaryTotal.totalPos.toFixed(0) : 0}</h5>

            <div> | </div>
            <h5>Total depositado: {summaryTotal ? summaryTotal.depositedMotoTotal.toFixed(0) : 0}</h5>

          </div>
        </div>
      </PageHeader>
      <DepositTable
        liquidations={liquidations}
        loading={loading}
      />
    </>
  )
}

export default DepositListContainer
