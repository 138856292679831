import { useAxios } from '../../hooks'
import { IAuthUserResponse } from '../auth/auth.interface'
import { useAuthStore } from '../auth/auth.service'
import { ITransport } from '../location/location.interface'

export const useTransporterList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ITransport[]>()
  
  const getTransporters = () => {
  
    execute({
      method: 'GET',
      url: '/transport',
      params: {  
        countryCode: authUser.user.company.countryCode,
      }
    })

  }
  
  return {
    getTransporters,
    loadingTransporters: loading,
    transporters: response ? response : []
  }
  
}

export const useUserToTransporterList = () => {
  const { execute, response, loading } = useAxios<IAuthUserResponse[]>()
  const getUsersToTransporter = (search: string, conveyorId: string) => {
    
    execute({
      method: 'GET',
      url: '/maintenance/user/transporter',
      params: {  
        search: search,
        transporterId: conveyorId === 'ALL' ? '' : conveyorId
      }
    })
  
  }
    
  return {
    getUsersToTransporter,
    loading: loading,
    users: response ? response : []
  }
    
}
