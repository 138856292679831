import React, { useEffect } from 'react'
import { Button, Table, Tooltip } from 'antd'
import { IAuthUserResponse } from '../../../../../services/auth/auth.interface'
import { ColumnsType } from 'antd/lib/table'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useUserStore } from '../../../../../services/user/user.service'
import { AndroidFilled, DeleteOutlined, EditOutlined, KeyOutlined, WindowsFilled } from '@ant-design/icons'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useModal } from '../../../../../context/ModalContext'
import { useDeleteUser } from '../../../../../services/user-create/user-create.service'

interface IUsersListTableProps {
  users: IAuthUserResponse[]
  loading: boolean
}

const UsersListTable: React.FC<IUsersListTableProps> = ({
  users, loading
}) => {
  const { openDrawer } = useDrawer()
  const { setUserSelected, setUserUpdated } = useUserStore()
  const { authUser, roleUser } = useAuthStore()
  const { openModal } = useModal()
  const { deleteUser, userDeleted } = useDeleteUser()

  const handleSelected = (code: string, title: string, data: IAuthUserResponse) => {
    setUserSelected(data)
    openDrawer(code, title)
  }

  useEffect(() => {
    if (userDeleted) {
      setUserUpdated(userDeleted)
    }
  }, [userDeleted])

  const verifyShowLogout = (user: IAuthUserResponse) => {
    return user?.session?.length > 0 &&
      user.id !== authUser.user.id &&
      (roleUser?.name === 'ADMIN' ||
      roleUser?.name === 'MASTER' || roleUser?.name === 'ADMIN_STORE' || roleUser?.name === 'SUPER_MASTER')
  }

  const verifyShowEditPassword = () => {
    return roleUser?.name === 'ADMIN' ||
      roleUser?.name === 'MASTER' || roleUser?.name === 'ADMIN_STORE' || roleUser?.name === 'SUPER_MASTER'
  }

  const openModalLogout = (data: IAuthUserResponse, platform: string) => {
    data.platform = platform
    setUserSelected(data)
    openModal('USER_LOGOUT', 'Cerrar Sesión')
  }

  const handleDeleteUser = (data: IAuthUserResponse) => {
    deleteUser(data.id)
  }

  const columns: ColumnsType<IAuthUserResponse> = [
    {
      title: 'Usuario',
      dataIndex: 'email',
      width: '30%', align: 'center',
      render: (_, user) => <>
        {user.email}<br></br>{user.fullname}<br></br>{user.phone}
      </>
    },
    {
      title: 'Transportadora', dataIndex: 'transporterId', width: '30%', align: 'center',
      render: (_, user) => <>{user.transporter?.name}</>,
    },
    {
      title: 'Rol', dataIndex: 'rolId', width: '15%', align: 'center',
      render: (_, user) => <>{user.role.description}</>,
    },
    {
      title: 'Acciones', dataIndex: 'id', width: '15%', align: 'center',
      render: (_, user) => <>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Tooltip title="Editar">
            <Button 
              shape="circle" 
              icon={<EditOutlined />} 
              onClick={() => handleSelected('USER_TRANSPORTER_EDIT', 'Editar usuario', user)}
            />
          </Tooltip>
          { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' 
            || authUser.user.role.name === 'SUPER_MASTER' ? <>
              <Tooltip title="Eliminar">
                <Button 
                  shape="circle" 
                  icon={<DeleteOutlined />} 
                  onClick={() => handleDeleteUser(user)}
                />
              </Tooltip>
            </> : null }
          
          { verifyShowEditPassword() &&
            <Tooltip title="Cambiar Contraseña">
              <Button 
                shape="circle" 
                icon={<KeyOutlined />} 
                onClick={() => handleSelected('USER_EDIT_PASSWORD', 'Cambiar contraseña', user)}
              />
            </Tooltip>
          }

          { verifyShowLogout(user) ? <> {
            user.session.length && user.session.find(it => it.isSession && it.platform === 'web') ? <>
              <Tooltip title="Cerrar Sesión Web">
                <Button 
                  shape="circle" 
                  icon={<WindowsFilled />} 
                  onClick={() => openModalLogout(user, 'web')}
                />
              </Tooltip>
            </> : <></> }
          {
            user.session.length && user.session.find(it => it.isSession && it.platform === 'android') ? <>
              <Tooltip title="Cerrar Sesión Android">
                <Button 
                  shape="circle" 
                  icon={<AndroidFilled />} 
                  onClick={() => openModalLogout(user, 'android')}
                />
              </Tooltip>
            </> : <></> }
          </> : null }
        </div>
      </>,
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={users}
      columns={columns}
      rowClassName="editable-row"
      loading={loading}
      size="small"
    />
  )
}

export default UsersListTable
