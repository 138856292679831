import { IAuthResponse } from '../auth/auth.interface'
import { IUserCreateRequest } from './user-create.interface'
import { IUserCreateSchema } from './user-create.schema'

export const mapToUserCreateRequest = (
  userCreateSchema: IUserCreateSchema,
  authUser: IAuthResponse,
  companyIdGlobal: number | undefined,
): IUserCreateRequest => {
  return {
    id: userCreateSchema.id,
    email: userCreateSchema.email,
    password: userCreateSchema.password,
    fullname: userCreateSchema.fullname,
    phone: userCreateSchema.phone ? `${userCreateSchema.phone}` : '',
    roleId: userCreateSchema.roleId,
    companyId: userCreateSchema.id ? userCreateSchema.companyId !== 0 ? userCreateSchema.companyId : 
      (companyIdGlobal || authUser.user.company.id) : (companyIdGlobal || authUser.user.company.id),
    status: userCreateSchema.status,
    isActive: userCreateSchema.isActive,
    roleName: userCreateSchema.roleName,
    userMail: userCreateSchema.userMail,
    warehouseId: parseInt(`${userCreateSchema.warehouseId}`),
    warehouseName: userCreateSchema.warehouseName,
    markerIcon: {
      color: userCreateSchema.markercolor,
      icon: userCreateSchema.iconColor,
    }
  }
}

export const mapToUserTransporterCreateRequest = (
  userCreateSchema: IUserCreateSchema,
  authUser: IAuthResponse
): IUserCreateRequest => {

  return {
    id: userCreateSchema.id,
    email: userCreateSchema.email,
    password: userCreateSchema.password,
    fullname: userCreateSchema.fullname,
    companyId: authUser.user.company.parentId,
    phone: userCreateSchema.phone ? `${userCreateSchema.phone}` : '',
    roleId: userCreateSchema.roleId,
    transporterId: userCreateSchema.transporterId,
    status: userCreateSchema.status,
    isActive: userCreateSchema.isActive,
    roleName: userCreateSchema.roleName,
    userMail: userCreateSchema.userMail,
    markerIcon: {
      color: userCreateSchema.markercolor,
      icon: userCreateSchema.iconColor,
    }
  }
}
