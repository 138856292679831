import React, { FC, useEffect } from 'react'
import { DatePicker, Form, FormInstance, Input, Select, Typography } from 'antd'
import { 
  useLocationLevel1,
  useLocationLevel2,
  useLocationLevel3,
} from '../../../../../../services/location/location.service'
import { IOrderUpdateSchema } from '../../../../../../services/order-update/order-update.schema'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'

const { Title } = Typography

interface IOrderShippingPartProps {
  form: FormInstance<IOrderUpdateSchema>
}

const OrderAgencyShippingPart: FC<IOrderShippingPartProps> = ({ form }) => {
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form)
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form)
  const districtId = Form.useWatch(['shipping', 'districtId'], form)
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } = useLocationLevel1()
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } = useLocationLevel2()
  const { getLocationsGeoLevel3, loadingGeo3, locationsGeolevel3 } = useLocationLevel3()
  const managementType = Form.useWatch(['managementType'], form)
  const blockFields = Form.useWatch(['blockFields'], form)
  const warehouseOrderAgency = Form.useWatch(['warehouseAgency'], form)

  const handleChangeProvince = (ubigeoId: string) => {
    getLocationsGeoLevel3({
      parentId: Number.parseInt(ubigeoId)
    })
  }

  const handleChangeDepartment = (ubigeoId: string) => {
    getLocationsGeoLevel2({
      parentId: Number.parseInt(ubigeoId)
    })
  }

  const disabledDate: RangePickerProps['disabledDate'] = current => {

    if (managementType === 'PARTNER') {

      const hour = warehouseOrderAgency && warehouseOrderAgency.formatTimeAgency && 
        warehouseOrderAgency.formatTimeAgency.length > 0 ? warehouseOrderAgency.formatTimeAgency.split(':') : []

      const newHour = hour.length > 0 ? parseInt(`${hour[0]}`) : 0
      const newMin = hour.length > 0 ? parseInt(`${hour[1]}`) : 0

      const now = moment().utcOffset(-5)
      const today = moment().startOf('day').add(newHour, 'h').add(newMin, 'm')

      if (now > today) {
        return current && current < moment().add(1, 'd').startOf('day') || current.day() === 0
      }

      return current.isBefore(now.startOf('day')) || current.day() === 0

    } else {

      return current && current < moment().startOf('day')

    }

  }

  useEffect(() => {
    if (departmentId && locationsGeolevel1.length > 0) {
      const dpto = locationsGeolevel1.find(d => `${d.id}` === `${departmentId}`)

      if (dpto) {
        form.setFieldValue(['shipping', 'department'], dpto?.name)
        handleChangeDepartment(`${dpto.id}`)
      }
    }
  }, [departmentId, locationsGeolevel1])

  useEffect(() => {
    if (provinceId && locationsGeolevel2.length > 0) {
      const prov = locationsGeolevel2.find(d => `${d.id}` === `${provinceId}`)

      if (prov) {
        form.setFieldValue(['shipping', 'province'], prov?.name)
        handleChangeProvince(`${prov.id}`)
      }
    }
  }, [provinceId, locationsGeolevel2])

  useEffect(() => {
    if (districtId && locationsGeolevel3.length > 0) {
      const dist = locationsGeolevel3.find(d => `${d.id}` === `${districtId}`)

      if (dist) {
        form.setFieldValue(['shipping', 'district'], dist?.name)
      }
    }
  }, [districtId, locationsGeolevel3])

  useEffect(() => {
    getLocationsGeoLevel1()
  }, [])

  return <>
    <Title level={4}>Envío</Title>

    <Form.Item
      hidden
      label="ID"
      name={['shipping', 'id']}>
      <Input />
    </Form.Item>

    <Form.Item
      label="Departamento"
      name={['shipping', 'departmentId']}
      rules={[{ required: true, message: '' }]}>
      <Select
        onChange={handleChangeDepartment}
        showSearch
        optionFilterProp="label"
        loading={loadingGeo1}
        disabled={blockFields}
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={locationsGeolevel1?.map(e => ({
          label: e.name,
          value: e.id,
        }))}
      />
    </Form.Item>

    <Form.Item
      label="Provincia"
      name={['shipping', 'provinceId']}
      rules={[{ required: true, message: '' }]}>
      <Select
        onChange={handleChangeProvince}
        showSearch
        optionFilterProp="label"
        loading={loadingGeo2}
        disabled={blockFields}
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={locationsGeolevel2?.map(e => ({
          label: e.name,
          value: e.id,
        }))}
      />
    </Form.Item>

    <Form.Item
      label="Distrito"
      name={['shipping', 'districtId']}
      rules={[{ required: true, message: '' }]}>
      <Select
        showSearch
        optionFilterProp="label"
        loading={loadingGeo3}
        disabled={blockFields}
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={locationsGeolevel3?.map(e => ({
          label: e.name,
          value: e.id,
        }))}
      />
    </Form.Item>

    <Form.Item label="Fecha de envío"
      name={['shipping', 'scheduleDate']}
      rules={[{ required: false, message: 'Seleccionar una fecha de entrega' }]}
    >
      <DatePicker
        disabled={blockFields}
        disabledDate={disabledDate}
        inputReadOnly={true}
        showToday={false}
        format={'DD/MM/YYYY'} style={{ width: '100%' }} />
    </Form.Item>

    <Form.Item
      label="Dirección 1"
      name={['shipping', 'address1']} rules={[{ required: false, message: '' }]}>
      <Input disabled={blockFields}/>
    </Form.Item>

    <Form.Item
      label="Dirección 2"
      name={['shipping', 'address2']}
    >
      <Input disabled={blockFields}/>
    </Form.Item>

    <Form.Item
      label="Referencia"
      name={['shipping', 'reference']} rules={[{ required: false, message: '' }]}>
      <Input disabled={blockFields}/>
    </Form.Item>

    <Form.Item
      label="GPS"
      name={['shipping', 'gps']}
      rules={[{
        required: false,
        message: '',
        pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/
      }]}>
      <Input
        placeholder='Latitud,Longitud' disabled={blockFields}
      />
    </Form.Item>

    <Form.Item
      hidden
      name={['shipping', 'department']}>
      <Input />
    </Form.Item>

    <Form.Item
      hidden
      name={['shipping', 'district']}>
      <Input />
    </Form.Item>

    <Form.Item
      hidden
      name={['shipping', 'province']}>
      <Input />
    </Form.Item>

  </>
}

export default OrderAgencyShippingPart
