import React, { FC, useEffect } from 'react'
import { IOrderItemResponse } from '../../../../../services/order-list/order.interface'
import { Button, DatePicker, Divider, Form, Input, InputNumber, Select, Spin } from 'antd'
import { useLocationLevel3 } from '../../../../../services/location/location.service'
import Title from 'antd/lib/typography/Title'
import moment from 'moment'
import { RangePickerProps } from 'antd/lib/date-picker'
import { IOrderRecycleSchema } from '../../../../../services/order-level/order-recycle.schema'
import { useOrderRecycleCustomHistory } from '../../../../../services/order-level/order-level.service'

interface IOrderRecycleEditFormProps {
  initialValues: IOrderItemResponse | null
  onSubmit: (data: IOrderRecycleSchema) => void
}
  
const OrderRecycleForm: FC<IOrderRecycleEditFormProps> = ({ initialValues, onSubmit }) => {
  const [form] = Form.useForm<IOrderRecycleSchema>()
  const { getLocationsGeoLevel3, loadingGeo3, locationsGeolevel3 } = useLocationLevel3()
  const districtId = Form.useWatch(['districtCode'], form)
  const { getOrderRecycle, orderRecycle, loadingRecycle } = useOrderRecycleCustomHistory()

  useEffect(() => {
    const districts = locationsGeolevel3 ? locationsGeolevel3.filter(f => f.flagContraentrega) : []

    if (districtId && districts.length > 0) {
      const dist = districts.find(d => `${d.id}` === `${districtId}`)

      if (dist) {
        form.setFieldValue(['districtName'], dist?.name)
      }
    }
  }, [districtId, locationsGeolevel3])

  useEffect(() => {
    if (initialValues) {

      getLocationsGeoLevel3({
        parentId: parseInt(initialValues.shipping.provinceCode),
      })

      if (initialValues.quantityRecycled > 0) {

        getOrderRecycle(initialValues.id)

      } else {

        form.setFieldValue('orderId', initialValues.id)

      }
    }
  }, [initialValues])

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current < moment().endOf('day')
  }

  useEffect(() => {
    if (orderRecycle && initialValues) {

      form.setFieldsValue({
        districtCode: `${orderRecycle.districtCode}`,
        districtName: '',
        address: orderRecycle.address1,
        address1: orderRecycle.address2,
        reference: orderRecycle.reference,
        gps: orderRecycle.gps,
        phone: orderRecycle.phone,
        name: orderRecycle.fullName,
        scheduleDate: orderRecycle?.deliveryDate ? moment(new Date(orderRecycle.deliveryDate), 'DD/MM/YYYY') : '',
      })

    }
  }, [orderRecycle])

  return <>
    <Spin spinning={loadingRecycle}>
      <Form
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        name="order"
        labelWrap
        onFinish={onSubmit}
      >

        <Form.Item name={['orderId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['districtName']} hidden>
          <Input />
        </Form.Item>

        <Title level={5}>Datos cliente</Title>

        <Form.Item
          label="Nombres"
          name={['name']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Apellidos"
          name={['lastName']}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Móvil"
          name={['phone']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <InputNumber
            controls={false}
            maxLength={11}
            style={{ display: 'inline-block', width: '100%' }}/>
        </Form.Item>

        <Title level={5}>Ubicación</Title>

        <Form.Item
          label='Distrito'
          name={['districtCode']}
          rules={[{ required: true, message: '' }]}>
          <Select
            showSearch
            optionFilterProp="label"
            loading={loadingGeo3}
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
            options={locationsGeolevel3.filter(f => f.flagContraentrega)?.map(e => ({
              label: e.name,
              value: `${e.id}`,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Dirección 1"
          name={['address']} rules={[{ required: true, message: '' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Dirección 2"
          name={['address1']} rules={[{ required: false, message: '' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Referencia"
          name={['reference']} rules={[{ required: false, message: '' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="GPS"
          name={['gps']}
          rules={[{
            required: true,
            message: '',
            pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
          }]}>
          <Input
            placeholder='Latitud,Longitud'
          />
        </Form.Item>

        <Form.Item label="Nueva Fecha entrega"
          name={['scheduleDate']}
          rules={[{ required: true, message: '' }]}
        >
          <DatePicker format={'DD/MM/YYYY'} disabledDate={disabledDate} style={{ width: '100%' }}/>
        </Form.Item>

        <Divider />

        { initialValues && initialValues?.quantityRecycled > 0 ? <>
        
        </> : <>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large">
              Guardar
            </Button>
          </Form.Item>
        </> }

      </Form>
    </Spin>
  </>
}

export default OrderRecycleForm
