import React, { FC, useEffect } from 'react'
import { Modal, Rate, Table, TableColumnsType, Tag, Tooltip } from 'antd'
import { 
  ORDER_DISPATCH_STATUS_LABEL, ORDER_DISPATCH_STATUS_LIST, ORDER_STATUS_LABEL, ORDER_STATUS_LIST,
} from '../../../../../services/order-create/order.params'
import { OrderActionIcons } from '..'
import { useDrawer } from '../../../../../context/DrawerContext'
import { CALL_STATUS_LABEL } from '../../../../../services/pre-order/pre-order.params'
import { IOrderDeliveryResponse, IOrderItemResponse } from '../../../../../services/order-list/order.interface'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { formatDateUTC } from '../../../../../utils/date'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { 
  CalendarOutlined, CheckCircleFilled, CustomerServiceFilled,
  DollarOutlined, DownloadOutlined, InteractionOutlined, ReloadOutlined, SendOutlined, 
  WhatsAppOutlined
} from '@ant-design/icons'
import { useModal } from '../../../../../context/ModalContext'
import { useUserRolStore } from '../../../../../services/pre-order/pre-order.edit'
import useMeasureY from '../../../../../hooks/use-measurey'
import { useOrderRescheduled } from '../../../../../services/order-level/order-level.service'
import { Document, Image, PDFDownloadLink, Page, StyleSheet, Text, View } from '@react-pdf/renderer'

interface IOrdersTableLevelProps {
  record: IOrderItemResponse[] | undefined
  loading: boolean
  handlePagination: (page: number) => void
  count: number
  currentPage: number
}

const confirm = Modal.confirm

const OrdersTableLevel: FC<IOrdersTableLevelProps> = ({ record, loading, handlePagination, count, currentPage }) => {
  const { openDrawer } = useDrawer()
  const { setOrderSelected, setOrderUpdated } = useOrderStore()
  const { authUser } = useAuthStore()
  const { openModal } = useModal()
  const { isStore } = useUserRolStore()
  const { windowSize } = useMeasureY()
  const { updateValidateOrderRescheduled, orderUpdated } = useOrderRescheduled()

  const handleSelectedOrder = (order: IOrderItemResponse) => {
    order.managementType = ''
    order.isOrderAgency = false
    setOrderSelected(order)
    openDrawer('ORDER_EDIT')
  }

  const handleSelectedOrderAgency = (order: IOrderItemResponse) => {
    order.managementType = 'OWN'
    order.isOrderAgency = true
    setOrderSelected(order)
    openDrawer('ORDER_AGENCY_EDIT')
  }

  const handleOrderPayments = (order: IOrderItemResponse) => {
    setOrderSelected(order)
    openModal('ORDER_PAYMENTS', 'Pagos')
  }

  const handleOrderDeliveries = (order: IOrderItemResponse) => {
    setOrderSelected(order)
    openModal('ORDER_DELIVERIES', 'Historial de entregas')
  }

  const handleOrderRecycle = (order: IOrderItemResponse) => {
    setOrderSelected(order)
    openDrawer('ORDER_RECYCLE')
  }

  const placeHolderRate = (order: IOrderItemResponse) => {

    const totalDelivered = order.customerHistory?.totalOrdersDelivered || 0
    const totalNotDelivered = order.customerHistory?.totalOrdersNotDelivered || 0

    return `#Entregados: ${totalDelivered} / #NoEntregados ${totalNotDelivered}`
  }

  const calculateRate = (order: IOrderItemResponse) => {

    const totalDelivered = order.customerHistory?.totalOrdersDelivered || 0
    const totalNotDelivered = order.customerHistory?.totalOrdersNotDelivered || 0

    const total = totalDelivered + totalNotDelivered

    let value = 0

    if (total > 0) {
      const percentage = (totalDelivered / total) * 100

      if (percentage > 85) value = 5
      else if (percentage < 85 && percentage > 70) value = 4
      else if (percentage < 70 && percentage > 60) value = 3
      else if (percentage < 60 && percentage > 40) value = 2
      else value = 0

    }

    return value
  }

  const validateRescheduled = (orderNumber: string, order: IOrderDeliveryResponse) => {
    const dateRescheduled = formatDateUTC(order.dateRescheduled)

    confirm({
      title: 'Validar reprogramación',
      content: `¿Desea validar la reprogramación del pedido 
      ${orderNumber} para la fecha ${dateRescheduled}?`,
      okText: 'SI',
      cancelText: 'NO',
      onOk() {
        updateValidateOrderRescheduled(order.id)
      },
    })
    
  }

  useEffect(() => {
    if (orderUpdated) {
      setOrderUpdated(orderUpdated)
    }
  }, [orderUpdated])

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    row: {
      flexGrow: 1,
      fontSize: 5,
      flexDirection: 'row',
    },
    col1: {
      width: '40%',
    },
    text: {
      width: '60%',
    },
    text1: {
      width: '100%',
    },
    textRigth: {
      textAlign: 'right',
    },
    textRigth1: {
      textAlign: 'right',
      fontSize: 8,
    },
    textMedia: {
      width: '50%',
    },
    section1: {
      borderColor: '#000',
      borderWidth: '0.5px',
      borderRadius: '5px',
      borderStyle: 'solid',
      marginTop: 4,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section: {
      marginTop: 8,
      marginLeft: 8,
      marginRight: 8,
      paddingTop: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section2: {
      marginTop: 2,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section3: {
      marginTop: 0,
      marginLeft: 8,
      marginRight: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    textCenter: {
      fontSize: 7,
      textAlign: 'center',
      fontWeight: 'ultrabold',
    },
    textCenter1: {
      fontSize: 7,
      width: '100%',
      textAlign: 'center',
      fontWeight: 'ultrabold',
    }
  })

  const DocPdf = (order: IOrderItemResponse) => {
    return (<Document>
      { order ? <>(
        <Page size={[162, 162]} key={order.orderNumber}>
          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={styles.textCenter1}> 
                {order.shipping.provinceName}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.text}>
                {order.orderNumber}
              </Text>
              <View style={styles.col1}>
                <Text style={styles.textRigth}>{order.transporter?.name}</Text>
              </View>
            </View>
          </View>
          <View style={styles.section1}>
            <View style={styles.row}>
              <View style={{ width: '70%' }}>
                <View style={styles.row}>
                  <Text style={styles.text1}>Almacén: {order.warehouseName}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.textRigth}>#Guía: {order.dispatchGuide?.guideNumber || '-'}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text}>Producto(s):</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text1}>
                    {order.orderDetails?.map(o => {
                      return `${o.quantity} ${o.sku.product?.name} ${o.sku.skuOptions?.map(op => op.option?.name)
                        .join('')}`
                        .concat(` - ${o.sku.company.setting.companyPrefix}`).concat('\n')
                    })}
                  </Text>
                </View>
              </View>
              <View style={{ width: '30%' }}>
                <Image source={'https://aliclikapp-api.herokuapp.com/order-public/generate-qr'
                  .concat(`/${order.orderNumber}`)} />
              </View>
            </View>
          </View>
          <View style={styles.section1}>
            <View style={styles.row}>
              <Text style={styles.text}>
                Monto a cobrar: {order.currency.symbol}{order.total}
              </Text>
              <Text style={styles.textRigth}>Distrito: {order.shipping.districtName || '-'}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.text}>NOTA: {order.note}{'\n'}
              CLIENTE: {order.customerHistory.name} {order.customerHistory.lastName}</Text>
            </View>
          </View>
          <View style={styles.section2}>
            <View style={styles.row}>
              <Text style={styles.textMedia}>Fecha entrega: {formatDateUTC(order.shipping.scheduleDate)}</Text>
              <Text style={styles.textMedia}>Fecha despacho: {formatDateUTC(order.shipping.dispatchDate)}</Text>
            </View>
          </View>
          <View style={styles.section3}>
            <View style={styles.row}>
              <Text style={styles.textMedia}>Reprogramado 1:</Text>
              <Text style={styles.textMedia}>No contesta 1:</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.textMedia}>Reprogramado 2:</Text>
              <Text style={styles.textMedia}>No contesta 2:</Text>
            </View>
          </View>
        </Page>)
      </> : null}
    </Document>)
  }

  const columnsLevel: TableColumnsType<IOrderItemResponse> = [
    {
      title: 'Order', dataIndex: 'id', width: 150, align: 'center',
      render: (_, order) => <>
        {authUser.user.role?.permissions?.order_edit ? (
          <a onClick={() => handleSelectedOrder(order)}>{order.orderNumber}</a>
        ) : (
          <>{order.orderNumber}</>
        )}<br></br>{order.user.fullname}
      </>,
    },
    {
      title: 'Actions', dataIndex: 'comunication', width: 80, align: 'center',
      render: (_, order) => <OrderActionIcons order={order} />
    },
    {
      title: 'Delivery', dataIndex: 'delivery', width: 100, align: 'center',
      render: (_, order) => <>
        {order.orderDeliveries && order.orderDeliveries.length > 0 ? <>
          <a onClick={() => handleOrderDeliveries(order)}>
            <SendOutlined style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
          </a>
        </> : null }
        <a onClick={() => handleOrderPayments(order)}>
          <DollarOutlined style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
        </a>
        { order.dispatchStatus === ORDER_DISPATCH_STATUS_LIST.TO_PREPARE ? <>
          <a onClick={() => handleSelectedOrderAgency(order)}>
            <Tooltip placement="bottom" title="Convertir a pedido de agencia">
              <ReloadOutlined style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
            </Tooltip>
          </a>
        </> : <></> }
        { order.callStatus === 'CONFIRMED' ? <>

          <PDFDownloadLink document={DocPdf(order)} fileName={`${order.orderNumber}.pdf`}>
            {({ blob, url, loading, error }) =>
              <Tooltip placement='bottom' title='rótulo'>
                <DownloadOutlined style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
              </Tooltip>
            }
          </PDFDownloadLink>

        </> : <></> }
        { authUser.user.company.isOrderRecycle === true && order.quantityRecycled === 0 &&
          order.callStatus === 'CONFIRMED' && order.isOrderAgency === false ? <>
            { order.status === 'DELIVERED' || order.status === 'PENDING_DELIVERY' || order.status === 'REFUSED' ||
              order.status === 'RESCHEDULED' || order.status === 'OUT_OF_STOCK' || order.status === 'NOT_DISPATCH' ? 
              null : <>

                { order.dispatchStatus === 'AVAILABLE' ? <>
              
                  <a onClick={() => handleOrderRecycle(order)}>
                    <Tooltip placement="bottom" title="Reciclar pedido">
                      <InteractionOutlined style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
                    </Tooltip>
                  </a>
                
                </> : null }

              </> }
              
          </> : null }
        { order.quantityRecycled > 0 ? <>
          <a onClick={() => handleOrderRecycle(order)}>
            <Tooltip placement="bottom" title="ver historial de cliente">
              <InteractionOutlined style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
            </Tooltip>
          </a>
        </> : null }
          
      </>
    },
    {
      title: 'Trazabilidad', width: 140,
      render: (_, { callStatus,
        warehouseName, status, dispatchStatus, dispatchGuide, warehousePhone,
        orderNumber, rescheduledNumber, orderDelivery, transporter }) => <>
        <Tag color={CALL_STATUS_LABEL[callStatus]?.color}>
          {CALL_STATUS_LABEL[callStatus]?.label}
        </Tag>
        <Tag color='#673AB7' hidden={warehouseName ? false : true}>
          {warehouseName}
        </Tag>
        { warehousePhone && warehousePhone.length > 0 && authUser.user.role.name !== 'STORE' ? <>
          <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=${warehousePhone}`}>
            <Tooltip placement="bottom" title="WhatsApp almacén">
              <WhatsAppOutlined
                className='OrderActionIcons-green'
              />
            </Tooltip>
          </a>
        </> : null }
        <Tag color='#0097A7'>
          {transporter?.name.toUpperCase()}
        </Tag>
        { dispatchGuide ? <>
          <Tag color='#FF4081'>
            {`#Guía: ${dispatchGuide.guideNumber}`}
          </Tag>
        </> : null}
        <Tag color='#757575'>
          {ORDER_DISPATCH_STATUS_LABEL[dispatchStatus].label}
        </Tag>
        { status !== ORDER_STATUS_LIST.DELIVERED ? <>
          <a target="_blank" rel="noreferrer" href={'https://api.whatsapp.com/send?phone=51953378661'}>
            <Tooltip placement="bottom" title="Soporte Entregas">
              <CustomerServiceFilled
                className='OrderActionIcons-blue'
              />
            </Tooltip>
          </a>
        </> : null}
        <Tag color={ORDER_STATUS_LABEL[status]?.color}>
          {ORDER_STATUS_LABEL[status]?.label}
        </Tag>
        { status === ORDER_STATUS_LIST.RESCHEDULED && authUser.user.role.name === 'ADMIN' && orderDelivery &&
          orderDelivery.isValidatedRescheduled === false && orderDelivery.dateRescheduled ? <>
            <a onClick={() => validateRescheduled(orderNumber, orderDelivery)}>
              <Tooltip placement="bottom" title='Validar reprogramación'>
                <Tag color={ORDER_STATUS_LABEL[status]?.color}>
                  {formatDateUTC(orderDelivery.dateRescheduled)}
                </Tag><CalendarOutlined style={{ color: '#FB8C00' }}/>
              </Tooltip>
            </a>
          </> : null }
        { orderDelivery && orderDelivery.isValidatedRescheduled === true && authUser.user.role.name === 'ADMIN' && 
          orderDelivery.dateRescheduled ? <>
            <Tag color={ORDER_STATUS_LABEL[status]?.color}>
              {formatDateUTC(orderDelivery.dateRescheduled)}
            </Tag><CalendarOutlined style={{ color: '#FB8C00' }} /><CheckCircleFilled style={{ color: '#FB8C00' }} />
          </> : null}
        <Tag color='#FB8C00' hidden={rescheduledNumber === 0}>
          {`#Visita: ${rescheduledNumber + 1}`}
        </Tag>
      </>
    },
    {
      title: 'Importes', dataIndex: 'total', width: 150, align: 'center',
      render: (_, { currency, total, totalPayment, shippingCost, totalDrop, status, 
        additionalDeliveryCost, returnCost }) => <>
        <b>&#128181; {currency?.symbol} {total.toFixed(2)}</b>
        <br/>&#128176; {currency?.symbol} {totalPayment > 0 ? totalPayment.toFixed(2) : '-'}
        { status === 'DELIVERED' || status === 'PENDING_DELIVERY' ? <>
          <br/>&#128666; {currency?.symbol} {shippingCost ? (shippingCost + (additionalDeliveryCost || 0)).toFixed(2) 
            : '- '}
        </> : null }
        { status === 'CANCEL' || status === 'NOT_RESPOND' || status === 'TRAVEL' || 
          status === 'OUT_OF_COVER' || status === 'REFUSED' || status === 'ANNULLED' ? <>
            <br/>&#128666; {currency?.symbol} {returnCost ? 
              (returnCost + (additionalDeliveryCost || 0)).toFixed(2) : '- '}
          </> : null }
        <br/>&#128230; {currency?.symbol} {totalDrop > 0 ? totalDrop.toFixed(2) : '-'}
      </>,
    },
    {
      title: 'Pagos', dataIndex: 'totalPayment', width: 100, align: 'center',
      render: (_, { totalCash, totalPOS, totalTransfer }) => <>
        💲 {totalCash > 0 ? `${totalCash.toFixed(2)} ` : '- '}
        <br/>&#128177; {totalTransfer > 0 ? `${totalTransfer.toFixed(2)} ` : '- '} 
        <br/>&#128179; {totalPOS > 0 ? `${totalPOS.toFixed(2)} ` : '- '}</>,
    },
    {
      title: 'Products', dataIndex: 'orderDetails', width: 240,
      render: (_, { orderDetails, productShopifyDetail }) => <>{ orderDetails.length > 0 ? orderDetails?.map(order => (
        <div key={order.id}>
          {order.quantity} {order.sku.product?.name}
          {order.sku.skuOptions?.filter(e => e.option?.name !== '_DEFAULT').map(op => <span key={op.id}>
            <span> / </span>{op.option?.name}
          </span>)}
        </div>
      )) : <>{productShopifyDetail}</>}</>,
    },
    {
      title: 'Nota', dataIndex: 'note', width: 140,
    },
    {
      title: 'Cliente', dataIndex: 'phone', width: 120, align: 'center',
      render: (_, order) => <>
        <Tooltip 
          title={placeHolderRate(order)}>
          {order.customerHistory?.name} {order.customerHistory?.lastName}<br/>{order.customerHistory?.phone}<br />
          <Rate disabled value={calculateRate(order)} />
        </Tooltip>
        { order.quantityRecycled > 0 ? <>
          <Tag color={'#ff9800'}>Cliente {order.quantityRecycled}</Tag>
        </> : null }
      </>
    },
    {
      title: 'Ubicación', dataIndex: 'addres1', width: 200, align: 'center',
      render: (_, { shipping }) => <>{shipping?.address1}
        <br/>{shipping?.address2 ? shipping.address2 : '-'}
        <br/>{shipping?.districtName}
        <br/>{shipping.provinceName}
      </>,
    },
    {
      title: 'Fechas', dataIndex: 'scheduleDate', align: 'center', width: 200,
      render: (_, { shipping, orderDeliveries, orderDelivery, isAssigned, createdAt }) => <>
        F. Registro: {formatDateUTC(createdAt)}<br/>
        F. Despacho: {formatDateUTC(shipping?.dispatchDate)}<br/>
        F. Entrega: {formatDateUTC(shipping?.scheduleDate)}<br/>
        {isAssigned === true ? orderDeliveries.length > 0 ? 
          <>Motorizado: {orderDeliveries[orderDeliveries.length - 1].user.email}</> : 
          <>Motorizado: {orderDelivery?.user?.email}</> : ''}
      </>,
    },
  ]

  const columnsStore: TableColumnsType<IOrderItemResponse> = [
    {
      title: 'Order', dataIndex: 'id', width: '10%', align: 'center',
      render: (_, order) => <>
        {authUser.user.role?.permissions?.order_edit ? (
          <a onClick={() => handleSelectedOrder(order)}>{order.orderNumber}</a>
        ) : (
          <>{order.orderNumber}</>
        )}
        { order.user.phone && order.user.phone.length > 0 && authUser.user.role.name === 'STORE' ? <>
        
          <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=${order.user.phone}`}>
            <Tooltip placement="bottom" title='Whatsapp tienda'>
              <WhatsAppOutlined
                className='OrderActionIcons-green'
              />
            </Tooltip>
          </a>
      
        </> : null }
      </>
    },
    {
      title: 'Trazabilidad', width: 150, align: 'center',
      render: (_, { callStatus, isOrderAgency, warehouseName, status, dispatchStatus, transporter }) => <>
        <Tag color={CALL_STATUS_LABEL[callStatus]?.color}>
          {CALL_STATUS_LABEL[callStatus]?.label}
        </Tag>
        <Tag color={ isOrderAgency ? '#FFB74D' : '#00796B'}>
          { isOrderAgency ? 'Agencia' : 'Contraentrega'}
        </Tag>
        <Tag color='#673AB7' hidden={warehouseName ? false : true}>
          {warehouseName}
        </Tag>
        <Tag color='#0097A7'>
          {transporter?.name.toUpperCase()}
        </Tag>
        <Tag color={ORDER_STATUS_LABEL[status]?.color}>
          {ORDER_STATUS_LABEL[status]?.label}
        </Tag>
        <Tag color='#757575'>
          {ORDER_DISPATCH_STATUS_LABEL[dispatchStatus].label}
        </Tag>
      </>
    },
    {
      title: 'Importes', dataIndex: 'total', width: '10%', align: 'center',
      render: (_, { currency, total, totalPayment, shippingCost, totalDrop, status }) => <>
        <b>&#128181; {currency?.symbol} {total.toFixed(2)}</b>
        <br/>&#128176; {currency?.symbol} {totalPayment > 0 ? totalPayment.toFixed(2) : '-'}
        { status === 'DELIVERED' ? <>
          <br/>&#128666; {currency?.symbol} {shippingCost ? shippingCost.toFixed(2) : '- '}
          <br/>&#128230; {currency?.symbol} {totalDrop > 0 ? totalDrop.toFixed(2) : '-'}
        </> : null }</>,
    },
    {
      title: 'Pagos', dataIndex: 'totalPayment', width: '10%', align: 'center',
      render: (_, { totalCash, totalPOS, totalTransfer }) => <>
        💲 {totalCash > 0 ? `${totalCash.toFixed(2)} ` : '- '}
        <br/>&#128177; {totalTransfer > 0 ? `${totalTransfer.toFixed(2)} ` : '- '} 
        <br/>&#128179; {totalPOS > 0 ? `${totalPOS.toFixed(2)} ` : '- '}</>,
    },
    {
      title: 'Products', dataIndex: 'orderDetails', width: '20%',
      render: (_, { orderDetails }) => <>{orderDetails?.map(order => (
        <div key={order.id}>
          {order.quantity} {order.sku.product?.name}
          {order.sku.skuOptions?.filter(e => e.option?.name !== '_DEFAULT').map(op => <span key={op.id}>
            <span> / </span>{op.option?.name}
          </span>)}
        </div>
      ))}</>,
    },
    {
      title: 'Distrito / Provincia', dataIndex: 'district', width: '10%', align: 'center',
      render: (_, { shipping }) => <>{shipping.districtName} / {shipping.provinceName}</>,
    },
    {
      title: 'F. entrega', dataIndex: 'scheduleDate', align: 'center',
      render: (_, { shipping }) => <>{formatDateUTC(shipping?.scheduleDate)}</>,
    },
  ]

  return <>
    <Table
      rowKey='id'
      columns={ isStore ? columnsStore : columnsLevel}
      dataSource={record}
      pagination={{ pageSize: 10, current: currentPage, total: count, simple: true, onChange(page, _) {
        handlePagination(page)
      }, }}
      size='small'
      loading={loading}
      scroll={{
        x: 2000,
        y: windowSize.height - 200,
      }}
    />
  </>
}

export default OrdersTableLevel
