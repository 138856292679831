import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import {
  useProductBestSellers,
  useProductsGlobal
} from '../../../../../services/product-global/product-global.service'
import { 
  Button, Card, Col, Row, Tooltip, Modal,
  Table, Input, Typography, Image, Spin, Select
} from 'antd'
import { SearchOutlined, WhatsAppOutlined } from '@ant-design/icons'
import {
  IProductGlobalResponse,
  ISkuGlobalResponse,
  IWarehouseGlobalResponse
} from '../../../../../services/product-global/product-global.interface'
import { ColumnsType } from 'antd/lib/table'
import './ButtonAnimation.css'
import Slider, { SliderMarks } from 'antd/lib/slider'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCategories } from '../../../../../services/category/category.service'
import { useWarehousePublicByCountry } from '../../../../../services/warehouse-list/warehouse-list.service'
import { useCatalogsByCompany } from '../../../../../services/catalog/catalog.service'
import { useCompanyDrop } from '../../../../../services/order-report-created/order-report-created.service'

const { Meta } = Card
const { Search } = Input
const { Title } = Typography
const { Option } = Select

const CatalogListContainer = () => {
  const { getProductsGlobal, products, loading } = useProductsGlobal()
  const { getProductsBestSellers, bestSellers, loadingBest } = useProductBestSellers()
  const { getCategories, categories, loadingCategory } = useCategories()
  const [ product, setProduct ] = useState<IProductGlobalResponse | null>(null)
  const [open, setOpen] = useState(false)
  const { authUser } = useAuthStore()
  const [priceMin, setPriceMin] = useState(0)
  const [priceMax, setPriceMax] = useState(500)
  const [searchText, setSearchText] = useState('')
  const [orderProd, setOrderProd] = useState('desc')
  const [catalogId, setCatalogId] = useState<any>(undefined)
  const [companyDropId, setCompanyDropId] = useState<any>(undefined)
  const [hiddenCatalog, setHiddenCatalog] = useState<boolean>(false)
  const [categorySelected, setCategorySelected] = useState('0')
  const [warSelected, setWarSelected] = useState<string>('ALL')
  const [warIdSelected, setWarIdSelected] = useState<string>('')
  const { getWarehouses, loadingWarehouses, warehouses } = useWarehousePublicByCountry()
  const { getCatalogsByCompany, loadingCatalogsStore, catalogs } = useCatalogsByCompany()
  const { getCompaniesDrop, companies, loadingCompanies } = useCompanyDrop()

  useEffect(() => {
    getCatalogsByCompany()
    getCompaniesDrop()
  }, [])

  useEffect(() => {
    if (catalogs && catalogs.length > 0) {

      getCategories()
      getWarehouses()

      if (catalogs.length === 1) {
        setHiddenCatalog(true)
      } else {
        setHiddenCatalog(false)
      }

      if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER') {
        setHiddenCatalog(false)
      }

      setCatalogId(`${catalogs[0].id}`)

      getProductsGlobal(searchText, 
        { priceMax, priceMin, orderBy: orderProd, categoryId: categorySelected, 
          warehouse: warSelected, catalogId: `${catalogs[0].id}`, companyId: companyDropId }
      )
      getProductsBestSellers(warIdSelected, `${catalogs[0].id}`, companyDropId)

    }
  }, [catalogs])

  const columns: ColumnsType<ISkuGlobalResponse> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: '80px',
    },
    {
      title: 'Variantes',
      dataIndex: 'skuOptions',
      render: (_, sku) => <>
        {sku.skuOptions?.map(opt => (
          <div key={opt.option?.name}>{opt.option?.variant?.name}: <b>{opt.option?.name}</b></div>
        ))}
      </>,
    },
  ]

  const columnsWarehouses: ColumnsType<IWarehouseGlobalResponse> = [
    {
      title: 'Almacén',
      dataIndex: 'name',
      render: (_, w) => <>
        {w.warehouse.name}
      </>,
    },
    {
      title: 'Contraentrega en',
      dataIndex: 'reference',
      align: 'center',
      render: (_, w) => <>
        {w.warehouse.reference}
      </>,
    },
    {
      title: 'Stock en bodega',
      dataIndex: 'stockStore',
      align: 'center',
      render: (_, w) => <>
        {w.stockStore}
      </>,
    },
    {
      title: 'Stock Disponible',
      dataIndex: 'stockVirtual',
      align: 'center',
      render: (_, w) => <>
        {w.stockVirtual}
      </>,
    },
  ]

  const onSearch = (value: string) => {
    setSearchText(value)

    if (catalogId) {
      getProductsGlobal(value, { 
        priceMax, priceMin, orderBy: orderProd, 
        categoryId: categorySelected, warehouse: warSelected, catalogId: catalogId, companyId: companyDropId
      })
    }
  }

  const marks: SliderMarks = {
    0: {
      style: {
        fontSize: 'small',
      },
      label: `Mín: ${authUser.user.company.countryCode === 'PER' ? 'S/' : 'Bs'}0`,
    },
    150: {
      style: {
        fontSize: 'small',
      },
      label: `${authUser.user.company.countryCode === 'PER' ? 'S/' : 'Bs'}150`,
    },
    300: {
      style: {
        color: '#000',
        fontSize: 'small',
      },
      label: `Máx: ${authUser.user.company.countryCode === 'PER' ? 'S/' : 'Bs'}300`,
    }
  }

  const onChange = (value: number | [number, number]) => {

    if (Array.isArray(value)) {
      setPriceMin(value[0])
      setPriceMax(value[1])

      const max = parseInt(`${value[1]}`) as number
      const min = parseInt(`${value[0]}`) as number

      if (catalogId) {
        getProductsGlobal(
          searchText, { 
            priceMax: max, priceMin: min, 
            orderBy: orderProd, categoryId: categorySelected, 
            warehouse: warSelected, catalogId: catalogId, companyId: companyDropId
          }
        )
      }
    }
  }

  const handleChangeOrder = (value: string) => {
    setOrderProd(value)

    if (catalogId) {

      getProductsGlobal(
        searchText, { 
          priceMax: priceMax, priceMin: priceMin, 
          orderBy: value, categoryId: categorySelected, 
          warehouse: warSelected, catalogId: catalogId, companyId: companyDropId
        }
      )

    }

  }

  const handleChangeCategoria = (value: string) => {
    setCategorySelected(value)

    if (catalogId) {

      getProductsGlobal(searchText, { 
        priceMax: priceMax, priceMin: priceMin, 
        orderBy: orderProd, categoryId: value, 
        warehouse: warSelected, catalogId: catalogId, companyId: companyDropId
      })

    }

  }

  const handleChangeWar = (warName: string) => {

    setWarSelected(warName)

    if (warehouses && warehouses.length > 0) {
      const war = warehouses.find(w => w.name === warName)

      if (war && catalogId) {
        setWarIdSelected(`${war.id}`)

        getProductsBestSellers(`${war.id}`, catalogId, companyDropId)
      }

    }

    if (catalogId) {

      getProductsGlobal(searchText, { 
        priceMax: priceMax, priceMin: priceMin, 
        orderBy: orderProd, categoryId: categorySelected, 
        warehouse: warName, catalogId: catalogId, companyId: companyDropId
      })

    }

  }


  const handleChangeCatalog = (catId: string) => {

    if (catId) {

      setCatalogId(catId)

      getProductsGlobal(searchText, { 
        priceMax: priceMax, priceMin: priceMin, 
        orderBy: orderProd, categoryId: categorySelected, 
        warehouse: warSelected, catalogId: catId, companyId: companyDropId
      })

      getProductsBestSellers(warIdSelected, catId, companyDropId)

    }

  }

  const handleChangeCompanyDrop = (companyId: string) => {

    if (companyId) {

      setCompanyDropId(companyId)

      getProductsGlobal(searchText, { 
        priceMax: priceMax, priceMin: priceMin, 
        orderBy: orderProd, categoryId: categorySelected, 
        warehouse: warSelected, catalogId: catalogId, companyId: companyId
      })

      getProductsBestSellers(warIdSelected, catalogId, companyId)

    }

  }

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center', 
          justifyContent: 'left', width: '100%', flexWrap: 'wrap' }}>

          <Search placeholder="Buscar producto" onSearch={onSearch}
            enterButton style={{ width: '250px' }} allowClear />

          <h5>Almacén</h5>
          <Select
            optionFilterProp='label'
            loading={loadingWarehouses}
            style={{ minWidth: '150px' }}
            onChange={handleChangeWar}
            value={warSelected}
            showSearch
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key='ALL' value='ALL' label={'TODOS'}>
              TODOS
            </Option>
            { warehouses.map(c => {
              return <>
                <Option key={c.id} value={c.name} label={c.name}>
                  {c.name}
                </Option>
              </>
            })}
          </Select>

          <h5>Categorías</h5>
          <Select
            optionFilterProp='label'
            loading={loadingCategory}
            style={{ minWidth: '130px' }}
            showSearch
            onChange={handleChangeCategoria}
            value={categorySelected}
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key='0' value='0' label={'TODOS'}>
              TODOS
            </Option>
            { categories.map(c => {
              return <>
                <Option key={c.id} value={c.id} label={c.name}>
                  {c.name}
                </Option>
              </>
            })}
          </Select>

          <h5 style={{ marginRight: '12px' }}>Precio Drop</h5>
          <Slider
            range
            marks={marks}
            min={0}
            max={300}
            defaultValue={[0, 150]}
            style={{ width: '20%', marginLeft: '0.5%' }}
            onAfterChange={onChange}
          />

          <h5 style={{ marginLeft: '12px', alignContent: 'center' }}>Ordernar por</h5>

          <Select
            optionFilterProp="label"
            style={{ minWidth: '150px' }}
            onChange={handleChangeOrder}
            value={orderProd}
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key='desc' value='desc' label={'Más Recientes'}>
              Más Recientes
            </Option>
            <Option key='asc' value='asc' label={'Más Antiguos'}>
              Más Antiguos
            </Option>
          </Select>

          { catalogs.length > 0 && hiddenCatalog === false ? <>

            <h5 style={{ marginLeft: '12px', alignContent: 'center' }}>Catálogo</h5>
            <Select
              optionFilterProp='label'
              style={{ minWidth: '150px' }}
              onChange={handleChangeCatalog}
              showSearch
              value={catalogId}
              loading={loadingCatalogsStore}
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              { catalogs.map(c => {
                return <>
                  <Option value={`${c.id}`} label={c.name}>
                    {c.name}
                  </Option>
                </>
              })}
            </Select>
            
          </> : null }

          { companies && companies.length > 0 ? <>

            <h5 style={{ marginLeft: '12px', alignContent: 'center' }}>Proveedor</h5>
            <Select
              optionFilterProp='label'
              style={{ minWidth: '150px' }}
              onChange={handleChangeCompanyDrop}
              value={companyDropId}
              showSearch
              loading={loadingCompanies}
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              { companies.map(c => {
                return <>
                  <Option value={`${c.id}`} label={c.name}>
                    {c.name}
                  </Option>
                </>
              })}
            </Select>

          </> : null }

        </div>
      </PageHeader>
      
      <div 
        style={{ display: 'flex', gap: '.5rem', 
          alignItems: 'center', justifyContent: 'start', width: '100%', marginRight: '24px' }}
      >
        <Title level={5} style={{ marginLeft: 24, color: '#000FFF' }}>Productos ganadores del mes ⭐</Title>
      </div>
    
      <Spin spinning={loadingBest}>
        <Row gutter={[12, 12]} style={{ padding: '0.5rem 1.0rem' }}>
          { bestSellers.map((p) => (

            <Col xs={12} xl={4} span={4} key={p.name}>
              <Card
                style={{ padding: '0.5rem 0.5rem' }}
                hoverable
                cover={<Image 
                  alt="example" src={p.urlImage} 
                  style={{ width: '100%', height: `${200}px`, objectFit: 'cover' }} />}
              >
                <Tooltip title={`Stock Disponible: ${p.stockTotal}`} color={'geekblue'} zIndex={0}>
                  <Meta title="" description={p.name} 
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: 13 }}/>
                  <div className="additional" style={{ fontSize: 12 }}>
                    <p className="price"><b>{p.skus[0].currency.symbol} {p.salePriceDrop.toFixed(2)} (Proveedor)</b></p>
                    <p className="price">{p.skus[0].currency.symbol} {p.retailPriceSuggested.toFixed(2)} (sugerido)</p>
                    <p>{p.shortDescription}</p>
                    
                  </div>
                  <Button type="primary" onClick={() => {
                    setOpen(true)
                    setProduct(p)
                  }} className='custom-button'>
                    Ver detalles <SearchOutlined />
                  </Button>
                  <p style={{ fontSize: 12 }}>Proveedor: {p.skus[0].company.name}</p>
                </Tooltip>
              </Card>
            </Col>
          )) }
        </Row>
      </Spin>

      <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center', 
        justifyContent: 'start', width: '100%', marginRight: '24px' }}>

        <Title level={5} style={{ marginLeft: 24 }}>  Productos</Title>

      </div>
      
      <Spin spinning={loading}>
        <Row gutter={[12, 12]} style={{ padding: '0.5rem 1.0rem' }}>
          { products.map((p) => (

            <Col xs={12} xl={4} span={4} key={p.id}>
              <Card
                style={{ padding: '0.5rem 0.5rem' }}
                hoverable
                cover={
                  <Image 
                    alt='example' src={p.urlImage}
                    style={{ width: '100%', height: `${200}px`, objectFit: 'cover' }} 
                  />}
              >
                <Tooltip title={`Stock Disponible: ${p.stockTotal}`} color={'geekblue'} zIndex={0}>
                  <Meta 
                    title="" description={p.name} 
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: 13 }} />
                  <div className="additional" style={{ fontSize: 12 }}>
                    <p className="price"><b>{p.skus[0].currency.symbol} {p.salePriceDrop.toFixed(2)}</b> (Proveedor)</p>
                    <p className="price">{p.skus[0].currency.symbol} {p.retailPriceSuggested.toFixed(2)} (sugerido)</p>
                    <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      {p.shortDescription || '-'}
                    </p>
                  </div>
                  <Button type="primary" onClick={() => {
                    setOpen(true)
                    setProduct(p)
                  }} className='custom-button'>
                    Ver detalles <SearchOutlined />
                  </Button>
                  <p style={{ fontSize: 12 }}>Proveedor: {p.skus[0].company.name}</p>
                </Tooltip>
              </Card>
            </Col>

          )) }
        </Row>
      </Spin>
      { product !== null ? <><Modal
        title={product.name}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        footer={[]}
      >
        <p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h3 style={{ color: 'blue' }}>Información adicional:</h3>
          </div>
          <a target="_blank" rel="noreferrer" href={product.urlReference}>{product.urlReference}</a>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
            <h4 style={{ color: 'green' }}>Contacto de proveedor:</h4>
            { product.skus[0].company.setting.phone ? <>
              <a target="_blank" rel="noreferrer" style={{ fontSize: 18, marginLeft: '12px' }}
                href={`https://api.whatsapp.com/send?phone=${product.skus[0].company.setting.phone}&text=${
                  'Hola! estoy interesado en este producto '.concat(product.name)
                    .concat(' del catalogo de Aliclik, por favor deseo mas información 😃')
                }`}>
                <Tooltip placement="bottom" title="Contacto de proveedor">
                  <WhatsAppOutlined
                    style={{ color: '#009688' }}
                  /> 
                </Tooltip>
              </a>
            </> : null }
          </div>
        </p>
        <h3>Descripción</h3>
        <p>{product.largeDescription}</p>

        <h3>Stock disponible contraentrega</h3>

        <Table
          rowKey="sku"
          columns={columns}
          dataSource={product.skus}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => <>
              <Table
                rowKey="id"
                columns={columnsWarehouses}
                dataSource={record.warehouseSkus.filter(t => t.stockStore > 0)}
                pagination={false}
                size="small"
              />
            </>,
            rowExpandable: (record) => !!record.warehouseSkus.length,
          }}
          size="small"
        />

        <p><b>Precio Sugerido Venta Ecom: </b>{product.skus[0].currency.symbol} 
          {product.retailPriceSuggested.toFixed(2)}</p>
        <p><b>Precio Dropshipping (UND): </b>{product.skus[0].currency.symbol} 
          {product.salePriceDrop.toFixed(2)}</p>
        
      </Modal></> : null }
      <div style={{ padding: '1rem 1.5rem' }}></div>
    </>
  )
}

export default CatalogListContainer
