import React from 'react'
import PrivateRoute from './PrivateRoute'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ROUTES } from './params'
import { Layout, SidebarApp } from '../components'
import { SignInView } from '../pages/SignIn'
import { 
  DeliveriesView,
  MyPreOrdersView,
  OrderShopifyView,
  OrdersAgencyView,
  OrdersView,
} from '../pages/OrderModule'
import { ProductsMaintView, StoresMaintView, UsersMaintView, MovementsMaintView, 
  WarehousesMainView } from '../pages/MaintenanceModule'
import { SettingsView } from '../pages/Settings'
import { CoveragesView, RoutesView } from '../pages/RoutesModule'
import TaskView from '../pages/Task/TaskView'
import { 
  DispatchGuideMaintView,
  OrderAssignmentView,
  OrdersToReturnView,
  ReturnMaintView,
} from '../pages/DispatchModule'
import { ValidationDepositView } from '../pages/LiquidationModule'
import { CatalogView, ManageCatalogView } from '../pages/Catalog'
import { AliNewsView } from '../pages/AliNews'
import { ReportView } from '../pages/Report'
import { HomeView } from '../pages/Home'
import { DashboardView } from '../pages/Dashboard'
import { TicketSupportView } from '../pages/Support'
import { FrequentQuestionView } from '../pages/FrequentQuestionModule'
import LandingView from '../pages/Landing/LadingView'
import UsersTransporterView from '../pages/MaintenanceModule/UsersTransporterView/UsersTransporterView'

export const RouterConfig = () => {
  return (
    <Routes>
      <Route path='/' >
        <Route path="/" element={<Navigate to={ROUTES.SIGN_IN.PATH} replace />} />
        <Route path={ROUTES.SIGN_IN.PATH} element={<SignInView />} />

        <Route path={'/preguntas-frecuentes'} element={<FrequentQuestionView />} />

        <Route path={'/home'} element={<LandingView />} />

        <Route element={<PrivateRoute Component={Layout} />} >
          <Route path={ROUTES.DASHBOARD.PATH} element={<DashboardView />} />
          <Route path={ROUTES.METRICS.PATH} element={<HomeView />} />
          <Route path={ROUTES.SETTING.PATH} element={<SettingsView />} />
          <Route path={ROUTES.TASK.PATH} element={<TaskView />} />
          <Route path={ROUTES.ALINEWS.PATH} element={<AliNewsView />} />
          <Route path={ROUTES.REPORTS.PATH} element={<ReportView />} />
          <Route path={ROUTES.SUPPORT.PATH} element={<TicketSupportView />} />
          <Route path={ROUTES.FREQUENT_QUESTION.PATH} element={<FrequentQuestionView />} />

          {/* Order module */}
          <Route
            path={ROUTES.ORDER.PATH}
            element={<Navigate to={ROUTES.ORDER.CHILDREN?.PRE_ORDERS.PATH as string} replace />}
          />

          <Route path={ROUTES.CATALOG.PATH} element={<SidebarApp />}>
            <Route path={ROUTES.CATALOG.CHILDREN?.GENERAL_CATALOG.PATH} element={<CatalogView />} />
            <Route path={ROUTES.CATALOG.CHILDREN?.MANAGE_CATALOG.PATH} element={<ManageCatalogView />} />
          </Route>

          <Route path={ROUTES.ORDER.PATH} element={<SidebarApp />}>
            <Route path={ROUTES.ORDER.CHILDREN?.PRE_ORDERS.PATH} element={<MyPreOrdersView />} />
            <Route path={ROUTES.ORDER.CHILDREN?.ORDERS.PATH} element={<OrdersView />} />
            <Route path={ROUTES.ORDER.CHILDREN?.ORDERS_AGENCY.PATH} element={<OrdersAgencyView />} />
            <Route path={ROUTES.ORDER.CHILDREN?.DELIVERIES.PATH} element={<DeliveriesView />} />
            <Route path={ROUTES.ORDER.CHILDREN?.ORDERS_SHOPIFY.PATH} element={<OrderShopifyView />} />
          </Route>
          {/* Maintenance module */}
          <Route
            path={ROUTES.MAINTENANCE.PATH}
            element={<Navigate to={ROUTES.MAINTENANCE.CHILDREN?.USERS?.PATH as string} replace />}
          />
          <Route path={ROUTES.MAINTENANCE.PATH} element={<SidebarApp />}>
            <Route path={ROUTES.MAINTENANCE.CHILDREN?.USERS?.PATH} element={<UsersMaintView/>} />
            <Route path={ROUTES.MAINTENANCE.CHILDREN?.USERS_CONVEYOR?.PATH} element={<UsersTransporterView />} />
            <Route path={ROUTES.MAINTENANCE.CHILDREN?.STORES?.PATH} element={<StoresMaintView />} />
            <Route path={ROUTES.MAINTENANCE.CHILDREN?.PRODUCTS?.PATH} element={<ProductsMaintView />} />
            <Route path={ROUTES.MAINTENANCE.CHILDREN?.MOVEMENTS?.PATH} element={<MovementsMaintView/>} />
            <Route path={ROUTES.MAINTENANCE.CHILDREN?.WAREHOUSES?.PATH} element={<WarehousesMainView/>} />
          </Route>
          <Route
            path={ROUTES.ROUTESMANAGEMENT.PATH}
            element={<Navigate to={ROUTES.ROUTESMANAGEMENT.CHILDREN?.COVERAGES?.PATH as string} replace />}
          />
          <Route path={ROUTES.ROUTESMANAGEMENT.PATH} element={<SidebarApp />}>
            <Route path={ROUTES.ROUTESMANAGEMENT.CHILDREN?.COVERAGES.PATH} element={<CoveragesView/>} />
            <Route path={ROUTES.ROUTESMANAGEMENT.CHILDREN?.ROUTES.PATH} element={<RoutesView/>} />
          </Route>
          <Route
            path={ROUTES.DISPATCHES.PATH}
            element={<Navigate to={ROUTES.DISPATCHES.CHILDREN?.DISPATCH_MANAGEMENT?.PATH as string} replace />}
          />
          <Route path={ROUTES.DISPATCHES.PATH} element={<SidebarApp />}>
            <Route path={ROUTES.DISPATCHES.CHILDREN?.DISPATCH_MANAGEMENT.PATH} element={<DispatchGuideMaintView/>} />
            <Route path={ROUTES.DISPATCHES.CHILDREN?.DISPATCH_ASSIGNMENT.PATH} element={<OrderAssignmentView/>} />
            <Route path={ROUTES.DISPATCHES.CHILDREN?.RETURN_MANAGEMENT.PATH} element={<ReturnMaintView />} />
            <Route path={ROUTES.DISPATCHES.CHILDREN?.ORDERS_TO_RETURN.PATH} element={<OrdersToReturnView /> } />
          </Route>
          <Route
            path={ROUTES.LIQUIDATIONS.PATH}
            element={<Navigate to={ROUTES.LIQUIDATIONS.CHILDREN?.DEPOSIT_VALIDATION?.PATH as string} replace />}
          />
          <Route path={ROUTES.LIQUIDATIONS.PATH} element={<SidebarApp />}>
            <Route path={ROUTES.LIQUIDATIONS.CHILDREN?.DEPOSIT_VALIDATION.PATH} element={<ValidationDepositView/>} />
          </Route>
        </Route>
      </Route>

      <Route path='/:code' element={<SignInView />}/>

      <Route path='*' element={<Navigate replace to='/' />} />

    </Routes>
  )
}
