export const ORDER_STATUS_LIST = {
  PENDING_DELIVERY: 'PENDING_DELIVERY',
  DELIVERED: 'DELIVERED',
  RESCHEDULED: 'RESCHEDULED',
  REFUSED: 'REFUSED',
  NOT_RESPOND: 'NOT_RESPOND',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  TRAVEL: 'TRAVEL',
  OUT_OF_COVER: 'OUR_OF_COVER',
  CANCEL: 'CANCEL',
  ANNULLED: 'ANNULLED',
  NOT_DISPATCH: 'NOT_DISPATCH',
}

export const ORDER_DISPATCH_STATUS_LIST = {
  TO_PREPARE: 'TO_PREPARE',
  PREPARED: 'PREPARED',
  PICKED: 'PICKED',
  OBSERVED: 'OBSERVED',
  TO_RETURN: 'TO_RETURN',
  RETURNED: 'RETURNED',
  IN_TRANSIT: 'IN_TRANSIT',
  STORE_CENTRAL: 'STORE_CENTRAL',
  LEFT_IN_WAREHOUSE: 'LEFT_IN_WAREHOUSE',
  IN_AGENCY: 'IN_AGENCY',
  AVAILABLE: 'AVAILABLE',
}

export const TICKET_STATUS_LIST = {
  OPEN: 'OPEN',
  NOT_PROCESSED: 'NOT_PROCESSED',
  ATTENDED: 'ATTENDED',
  IN_PROCESS: 'IN_PROCESS'
}

export const TICKET_STATUS_LABEL: { [key: string]: { label: string, color: string }} = {
  OPEN: {
    label: 'ABIERTO',
    color: 'geekblue'
  },
  NOT_PROCESSED: {
    label: 'NO PROCESADO',
    color: '#ff0066'
  },
  ATTENDED: {
    label: 'ATENDIDO',
    color: '#009688'
  },
  IN_PROCESS: {
    label: 'EN CURSO',
    color: '#FF9800',
  }
}

export const ORDER_DISPATCH_STATUS_LABEL: { [key: string]: { label: string }} = {
  TO_PREPARE: {
    label: 'POR PREPARAR'
  },
  PREPARED: {
    label: 'PREPARADO'
  },
  PICKED: {
    label: 'VALIDADO'
  },
  OBSERVED: {
    label: 'OBSERVADO'
  },
  TO_RETURN: {
    label: 'POR DEVOLVER'
  },
  RETURNED: {
    label: 'DEVUELTO'
  },
  IN_TRANSIT: {
    label: 'EN TRÁNSITO'
  },
  LEFT_IN_WAREHOUSE: {
    label: 'DEJADO EN ALMACÉN'
  },
  STORE_CENTRAL: {
    label: 'ALMACÉN CENTRAL'
  },
  IN_AGENCY: {
    label: 'EN AGENCIA'
  },
  AVAILABLE: {
    label: 'DISPONIBLE'
  }
}

export const TRACKING_STATUS_LIST = {
  DESTINATION: 'DESTINATION',
  TO_REGISTER: 'TO_REGISTER',
  REGISTERED: 'REGISTERED',
  TO_PREPARE: 'TO_PREPARE',
  PREPARED: 'PREPARED',
  OBSERVED: 'OBSERVED',
  LEAVE_IN_AGENCY: 'LEAVE_IN_AGENCY',
  DELIVERED: 'DELIVERED'
}

export const TRACKING_STATUS_LABEL: { [key: string]: { label: string, color: string } } = {
  TO_REGISTER: {
    label: 'POR REGISTRAR',
    color: '#02FBF5',
  },
  REGISTERED: {
    label: 'REGISTRADO',
    color: '#0502FB'
  },
  TO_PREPARE: {
    label: 'POR PREPARAR',
    color: '#B302FB',
  },
  PREPARED: {
    label: 'PREPARADO',
    color: '#1890ff',
  },
  OBSERVED: {
    label: 'OBSERVADO',
    color: '#FB020A',
  },
  LEAVE_IN_AGENCY: {
    label: 'DEJADO EN AGENCIA',
    color: '#FBA402'
  },
  DESTINATION: {
    label: 'DESTINO',
    color: '#2FE302'
  },
  DELIVERED: {
    label: 'ENTREGADO',
    color: '#95FF00'
  }
}

export const ORDER_STATUS_LABEL: { [key: string]: { label: string, color: string } } = {
  PENDING_DELIVERY: {
    label: 'PEND. DE ENTREGA',
    color: '#1890ff'
  },
  DELIVERED: {
    label: 'ENTREGADO',
    color: '#009688'
  },
  RESCHEDULED: {
    label: 'REPROGRAMADO',
    color: '#ff9800'
  },
  REFUSED: {
    label: 'RECHAZADO',
    color: '#ff0066'
  },
  NOT_RESPOND: {
    label: 'NO RESPONDE',
    color: '#ff9800'
  },
  OUT_OF_STOCK: {
    label: 'SIN STOCK',
    color: '#ff0066'
  },
  TRAVEL: {
    label: 'DE VIAJE',
    color: '#ff0066'
  },
  OUT_OF_COVER: {
    label: 'FUERA DE COBERTURA',
    color: '#FF8A65',
  },
  CANCEL: {
    label: 'CANCELADO',
    color: '#ff0066',
  },
  ANNULLED: {
    label: 'ANULADO',
    color: '#ff0066',
  },
  NOT_DISPATCH: {
    label: 'NO DESPACHADO',
    color: '#ff0066',
  },
}

export const CLOSING_HOURS_WAREHOUSE: { [key: string]: { agencyHour: string, orderHour: string } } = {
  'STOKEALO': {
    agencyHour: 'Horario de cierre Drop Shalom: 4pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 3pm'
  },
  'ALIPARTNER': {
    agencyHour: 'Horario de cierre Drop Shalom: 4pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 4pm'
  },
  'EVERMONT': {
    agencyHour: 'Horario de cierre Drop Shalom: LUNES A VIERNES: 3pm - SABADOS: 12:30pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 1pm'
  },
  'YUZU': {
    agencyHour: 'Horario de cierre Drop Shalom: 1pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 2pm'
  },
  'FENG FU': {
    agencyHour: 'Horario de cierre Drop Shalom: 3pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 1pm'
  },
  'FENIX': {
    agencyHour: 'Horario de cierre Drop Shalom: 2pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 3pm'
  },
  'GLAB': {
    agencyHour: 'Horario de cierre Drop Shalom: 2pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 3pm'
  },
  'WANKLIC GROUP': {
    agencyHour: 'Horario de cierre Drop Shalom: 4pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 00hrs<br/>Contra entrega provincia:4pm'
  },
  'AleVilla Importaciones': {
    agencyHour: 'Horario de cierre Drop Shalom: 3:30pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia:1pm'
  },
  'VOCH': {
    agencyHour: '',
    orderHour: ''
  },
  'FABI IMPORTACIONES': {
    agencyHour: '',
    orderHour: ''
  },
  'DROP HOUSE': {
    agencyHour: 'Horario de cierre Drop Shalom: 4pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 3pm'
  },
  'Shoppedi2': {
    agencyHour: 'Horario de cierre Drop Shalom: 3pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 3pm '
  },
  'ALFALION INVESTMENT': {
    agencyHour: 'Horario de cierre Drop Shalom: 1pm',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 2pm'
  },
  'SMARTBUY': {
    agencyHour: '',
    orderHour: ''
  },
  'SOMOS TVL': {
    agencyHour: 'Horario de cierre Drop Shalom: 11am',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 3pm'
  },
  'N-WOW': {
    agencyHour: 'Horario de cierre Drop Shalom: -',
    orderHour: 'Horario de cierre:<br />Contra entrega Lima: 11pm<br/>Contra entrega provincia: 3pm'
  }
}

export const WAREHOUSE_SHALOM_AGENCY: { [key: string]: { shalomAgency: string } } = {
  'STOKEALO': {
    shalomAgency: 'Lima / Lima / Pueblo Libre / Av. La Marina',
  },
  'ALIPARTNER': {
    shalomAgency: 'Lima / Lima / La Victoria / Jr. Raymondi',
  },
  'EVERMONT': {
    shalomAgency: 'Lima / Lima / San Borja / Aviación 2819',
  },
  'YUZU': {
    shalomAgency: 'Callao / Callao / Bellavista / Bellavista Callao',
  },
  'FENG FU': {
    shalomAgency: 'Lima / Lima / Santa Anita / Santa Anita',
  },
  'FENIX': {
    shalomAgency: 'Lima / Lima / Brena / Breña',
  },
  'GLAB': {
    shalomAgency: 'Lima / Lima / Brena / Breña',
  },
  'AleVilla Importaciones': {
    shalomAgency: 'Lima / Lima / San Borja / Aviación 2819',
  },
  'WANKLIC GROUP': {
    shalomAgency: 'Lima / Lima / La Victoria / Jr. Raymondi',
  },
  'VOCH': {
    shalomAgency: '',
  },
  'FABI IMPORTACIONES': {
    shalomAgency: 'LIMA / LIMA/ATE-VITARTE / HUAYCAN ENTRADA',
  },
  'DROP HOUSE': {
    shalomAgency: 'LIMA / LIMA / Ate- Vitarte/ Los Sauces',
  },
  'Shoppedi2': {
    shalomAgency: 'Lima / Lima / El Agustino / El Agustino',
  },
  'ALFALION INVESTMENT': {
    shalomAgency: 'Lima / Lima / Pueblo Libre / Av. La Marina'
  },
  'SMARTBUY': {
    shalomAgency: '',
  },
  'SOMOS TVL': {
    shalomAgency: 'Shalom lince , Ignacio Merino 2150',
  },
  'N-WOW': {
    shalomAgency: '',
  },
}
