import React, { FC, useEffect, useState } from 'react'
import { Select } from 'antd'
import { useCompanyList } from '../../services/company-list/company-list.service'
import { useCompanyStore } from '../../services/company-create/company-create.service'
import { ICompanyItemResponse } from '../../services/company-list/company-list.interface'
import { useAuthStore } from '../../services/auth/auth.service'

const { Option } = Select

interface ICompanyChangeContainerProps {
  type?: 'NORMAL' | 'PRINCIPAL';
  isAll: boolean
}

const CompanyChangeContainer: FC<ICompanyChangeContainerProps> = ({ type, isAll }) => {
  const { authUser } = useAuthStore()
  const { companies, loading } = useCompanyList()
  const [defaultSelected, setDefaultSelected] = useState<string>()
  const { setCompanyGlobal } = useCompanyStore()
  const [companyFiltered, setCompanyFiltered] = useState<ICompanyItemResponse[]>([])

  const handleChange = (companyId: string) => {
    const company = companies.find((c: ICompanyItemResponse) => c.id === +companyId)

    setDefaultSelected(companyId)
    setCompanyGlobal(company as ICompanyItemResponse)
  }

  useEffect(() => {
    if (companies?.length > 0) {
      const initCompany: ICompanyItemResponse = {
        id: 0,
        name: 'TODOS',
        type: '',
        code: '',
        countryCode: authUser.user.company.countryCode,
        totalConfirmedOrders: 0,
        totalAffiliates: 0,
        configBankAccount: false,
        isOwnMerchandise: false,
        setting: {
          amountDelivery: 0,
          companyPrefix: '',
          country: {
            code: '',
            name: '',
          },
          currency: {
            code: '',
            name: '',
            symbol: '',
          },
          dashboardUrls: []
        }
      }

      if (isAll) {
        companies.push(initCompany)
      }
      const companyList = companies.sort((a: ICompanyItemResponse, b: ICompanyItemResponse) => a.id - b.id)
      const filter = companyList?.filter((c: ICompanyItemResponse) => type ? c.type === type : true)
      const company = companyList[0]

      setCompanyFiltered(filter)
      setDefaultSelected(`${company.id}`)
      setCompanyGlobal(company as ICompanyItemResponse)
    }
  }, [companies])

  return (
    <Select
      loading={loading}
      placeholder="Seleccione compañía"
      style={{ minWidth: '180px' }}
      onChange={handleChange}
      showSearch
      value={defaultSelected}
      optionFilterProp="label"
      filterOption={(input, option) =>
        (option?.label as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {companyFiltered.map(company => (
        <Option
          key={company.id}
          value={`${company.id}`}
          label={company.name}
        >
          {company.name} {company.type ? '(' + company.type + ')' : ''}
        </Option>
      ))}
    </Select>
  )
}

export default CompanyChangeContainer
