import { useAxios } from '../../hooks'
import { IProductRemaining } from './report-remaining-prod.interface'

export const useProductRemainingList = () => {
  const { execute, response, loading } = useAxios<IProductRemaining[]>()

  const getProductsRemaining = (startDate: string, endDate: string, conveyorId: string, warehouseId: string) => {
    execute({
      method: 'GET',
      url: '/order/report/remaining-products',
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
      params: {
        startDate: startDate,
        endDate: endDate,
        conveyorId: conveyorId === 'ALL' ? '' : conveyorId,
        warehouseId: warehouseId
      }
    })
  }

  return {
    getProductsRemaining,
    loadingProduct: loading,
    products: response || []
  }
}
