import { useAxios } from '../../hooks'
import { useConveyorStore } from '../location/location.service'
import { ICoverageItemResponse } from './coverage-create.interface'
import { ICoverageCreateSchema } from './coverage-create.schema'

export const useCoverageCreate = () => {
  const { conveyorSelected } = useConveyorStore()
  const { execute, response, loading } = useAxios<ICoverageItemResponse>()

  const saveCoverage = (data: ICoverageCreateSchema) => {

    const dataRequest = {
      transporterId: conveyorSelected?.id,
      warehouseId: data.warehouse,
      ubigeoId: data.department,
      addDays: data.addDays || 0,
      deliveryCost: data.deliveryCost || 0,
      deliveryCostPremium: data.deliveryCostPremium || 0,
      returnCost: data.returnCost || 0,
      returnCostPremium: data.returnCostPremium || 0,
      motorizedCost: data.motorizedCost || 0,
      flagDeadLineTime: data.flagDeadLineTime || 0
    }

    execute({
      method: 'POST',
      url: '/transport/coverage',
      data: dataRequest,
    })
  }

  return {
    saveCoverage,
    loading,
    coverageUpdated: response
  }
}
