import { IAuthResponse } from '../auth/auth.interface'
import { ICreateDispatchGuideRequest, IUpdateDispatchGuideRequest } from './dispatch-guide-create.interface'
import { ICreateDispatchGuideSchema } from './dispatch-guide-create.schema'
import { formatInTimeZone } from 'date-fns-tz'

export const mapToDispatchGuideUpdateRequest = (
  authUser: IAuthResponse,
  schema: ICreateDispatchGuideSchema
): IUpdateDispatchGuideRequest => {
  return {
    shippingCost: parseFloat(`${schema.shippingCost || 0}`),
    originCost: parseFloat(`${schema.originCost || 0}`),
    destinationCost: parseFloat(`${schema.destinationCost || 0}`),
    photoMerchandiseDestination: schema.photoMerchandiseDestination,
    photoMerchandiseOrigin: schema.photoMerchandiseOrigin,
    photoReturnDestination: schema.photoReturnDestination,
    photoReturnOrigin: schema.photoReturnOrigin,
    dispatchDate: schema.dispatchDate,
    commentDestination: schema.commentDestination,
    urlGuideDocument: schema.urlGuideDocument,
    updatedBy: authUser.user.id,
    dispatchStatus: schema.dispatchStatus,
    guideCode: schema.guideCode,
    comment: schema.comment,
    urlVoucher: schema.urlVoucher,
    isCalculateProduct: schema.dispatchStatus === 'IN_AGENCY',
    motorizedSender: schema.motorizedSender,
    motorizedSenderId: schema.motorizedSenderId,
    motorizedRecipient: schema.motorizedRecipient,
    motorizedRecipientId: schema.motorizedRecipientId,
    departmentCode: `${schema.departmentCode}`,
    departmentName: schema.departmentName,
    provinceOriginCode: `${schema.provinceOriginCode}`,
    provinceCode: `${schema.provinceCode}`,
    provinceName: schema.provinceName,
    provinceOriginName: schema.provinceOriginName,
    departmentOriginCode: `${schema.departmentOriginCode}`,
    departmentOriginName: schema.departmentOriginName
  }
}

export const mapToDispatchGuideCreateRequest = (
  authUser: IAuthResponse,
  schema: ICreateDispatchGuideSchema
): ICreateDispatchGuideRequest => {

  const today = formatInTimeZone(new Date(), 'America/Lima', 'yyMMdd')
  const dptoOriginAbrev = schema.departmentOriginName.length <= 3 ? schema.departmentOriginName.toUpperCase() : 
    schema.departmentOriginName.replace(/\s/g, '').trim().substring(0, 3).toUpperCase()
  const dptoDestinationAbrev = schema.departmentName.length <= 3 ? schema.departmentName.toUpperCase() : 
    schema.departmentName.replace(/\s/g, '').trim().substring(0, 3).toUpperCase()
  const motorizedAbrev = schema.motorizedRecipient.name.replace(/\s/g, '').trim().toUpperCase()
  const guideNumber = `${dptoOriginAbrev}-${dptoDestinationAbrev}-${motorizedAbrev}-${today}`
  const ids = schema.order.length > 0 ? schema.order.map(o => o.id) : []
  
  return {
    guideNumber: guideNumber,
    motorizedRecipient: schema.motorizedRecipient,
    motorizedSender: schema.motorizedSender,
    motorizedRecipientId: schema.motorizedRecipientId,
    motorizedSenderId: schema.motorizedSenderId,
    shippingCost: parseFloat(`${schema.shippingCost || 0}`),
    urlGuideDocument: schema.urlGuideDocument,
    createdBy: authUser.user.id,
    companyId: authUser.user.company.id,
    departmentName: schema.departmentName,
    departmentCode: `${schema.departmentCode}`,
    provinceName: schema.provinceName,
    provinceCode: `${schema.provinceCode}`,
    dispatchStatus: schema.dispatchStatus,
    guideCode: schema.guideCode,
    warehouse: schema.warehouse,
    warehouseId: schema.warehouseId,
    orderIds: ids,
    transporterId: schema.transporterId,
    comment: schema.comment,
    urlVoucher: schema.urlVoucher,
    commentDestination: schema.commentDestination,
    departmentOriginCode: `${schema.departmentOriginCode}`,
    departmentOriginName: schema.departmentOriginName,
    provinceOriginCode: `${schema.provinceOriginCode}`,
    provinceOriginName: schema.provinceOriginName,
    originCost: schema.originCost,
    destinationCost: schema.destinationCost,
  }

}
