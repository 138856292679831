/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { useEffect, useState } from 'react'
//import moment from 'moment'
import { ORDER_STATUS_LIST } from '../order-create/order.params'
import { CALL_STATUS_LIST } from '../pre-order/pre-order.params'
import { IOrderFilter, IOrderResumen, IOrdersResponse, IOrderBaseResponse } from '../order-list/order.interface'

export const useOrderDeliveryList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IOrderBaseResponse>()
  const [orders, setOrders] = useState<IOrdersResponse[]>()
  const [ordersOriginal, setOrdersOriginal] = useState<IOrdersResponse[]>()
  const [resumen, setResumen] = useState<IOrderResumen>()

  const getOrders = (filter?: IOrderFilter) => {

    let url = '/order/delivery'

    if (authUser.user.role.name === 'ADMIN_STORE' || authUser.user.role.name === 'SELLER') {
      url = '/order/delivery/ecommerce'
    }

    execute({
      method: 'GET',
      url: url,
      params: {
        callStatus: CALL_STATUS_LIST.CONFIRMED,
        companyId: filter?.companyId === 0 ? '' : (filter?.companyId || authUser.user.company.id),
        endDate: filter?.endDate,
        startDate: filter?.startDate,
        status: filter?.statusList?.join(','),
        countryCode: authUser.user.company.countryCode,
        page: filter?.page ? filter.page : '',
        search: filter?.search ? filter.search : '',
        parentId: authUser.user.company.parentId,
        departmentName: filter?.departmentName === 'ALL' ? '' : filter?.departmentName,
        isContacted: filter?.isContacted === 'ALL' ? '' : filter?.isContacted,
        conveyorId: filter?.conveyorId === 'ALL' ? '' : filter?.conveyorId,
        filterRecycle: filter?.filterRecycle === 'ALL' ? '' : filter?.filterRecycle,
      },
    })
  }

  const orderFilter = (filter: { [key: string]: string } | null) => {
    const orderTemp = filter ? mapToOrderFilter(response ? response.result as IOrdersResponse[] : [], filter) : response
    const orderProcessed = mapToOrderTable(
      orderTemp as IOrdersResponse[],
      response ? response?.count : 0,
      response ? (response?.totalAmount._sum.total ? response.totalAmount._sum.total : 0) : 0
    )

    setOrders(orderProcessed.orders)
    setResumen(orderProcessed.resumen)
  }

  useEffect(() => {
    if (response) {
      const orderProcessed = mapToOrderTable(
        response.result,
        response ? response.count : 0,
        response ? (response?.totalAmount._sum.total ? response.totalAmount._sum.total : 0) : 0
      )

      setOrdersOriginal(orderProcessed.orders)
      setOrders(orderProcessed.orders)
      setResumen(orderProcessed.resumen)
    }
  }, [response])

  return {
    getOrders,
    loading,
    orders,
    resumen,
    orderFilter,
    provinceList: ordersOriginal?.length ? getProvinceList(ordersOriginal) : [],
    count: response ? response.count : 0,
    page: response ? response.page : 1,
  }
}

const mapToOrderTable = (orders: IOrdersResponse[], count: number, amount: number): {
  orders: IOrdersResponse[],
  resumen: IOrderResumen,
} => {
  let totalAmount = 0
  let totalOrder = 0
  let totalPayment = 0
  let totalCash = 0

  const ordersMapped = orders
    .map(orderLocation => {
      // Mapea campos de segundo nivel
      return {
        ...orderLocation,
        orders: orderLocation.orders.map(item => {
          const totalOrder = item.orders.length
          const totalCompleted = item.orders.filter(e => e.status !== ORDER_STATUS_LIST.PENDING_DELIVERY).length
          const totalDelivered = item.orders.filter(e => e.status === ORDER_STATUS_LIST.DELIVERED).length
          const totalRefused = item.orders.filter(e => e.status === ORDER_STATUS_LIST.REFUSED).length
          const totalRescheduled = item.orders.filter(e => e.status === ORDER_STATUS_LIST.RESCHEDULED).length
          const ratioCompleted = Math.round((totalCompleted / totalOrder) * 100)
          const ratioDelivered = Math.round((totalDelivered / totalOrder) * 100)
          const ratioRefused = Math.round((totalRefused / totalOrder) * 100)
          const ratioRescheduled = Math.round((totalRescheduled / totalOrder) * 100)

          return {
            ...item,
            totalAmount: item.orders?.length ? item.orders.map(e => e.total).reduce((a, b) => a + b) : 0,
            totalPayment: item.orders?.length ? item.orders.map(e => e.totalPayment).reduce((a, b) => a + b) : 0,
            totalCash: item.orders?.length ? item.orders.map(e => e.totalCash).reduce((a, b) => a + b) : 0,
            currency: item.orders[0]?.currency,
            totalOrder,
            totalCompleted,
            totalDelivered,
            totalRefused,
            totalRescheduled,
            ratioCompleted,
            ratioDelivered,
            ratioRefused,
            ratioRescheduled,
          }
        }),
      }
    })
    .map(orderLocation => {
      const totalAmount = orderLocation.orders.map(e => e.totalAmount)?.reduce((a, b) => a + b)
      const totalOrder = orderLocation.orders.map(e => e.totalOrder)?.reduce((a, b) => a + b)
      const totalPayment = orderLocation.orders.map(e => e.totalPayment)?.reduce((a, b) => a + b)
      const totalCash = orderLocation.orders.map(e => e.totalCash)?.reduce((a, b) => a + b)

      return {
        ...orderLocation,
        currency: orderLocation.orders[0]?.currency,
        totalAmount,
        totalOrder,
        totalPayment,
        totalCash,
      }
    })
    .map(orderLocation => {
      return {
        ...orderLocation,
        orders: orderLocation.orders.map(item => {
          const ratioAmount = Math.round((item.totalAmount / orderLocation.totalAmount) * 100)

          return {
            ...item,
            ratioAmount,
          }
        }),
      }
    })
    .map(orderLocation => {
      // Mapea campos de primer nivel
      totalAmount = totalAmount + orderLocation.totalAmount
      totalOrder = totalOrder + orderLocation.totalOrder
      totalPayment = totalPayment + orderLocation.totalPayment
      totalCash = totalCash + orderLocation.totalCash

      return {
        ...orderLocation,
        orders: orderLocation.orders.filter(e => e.totalOrder),
      }
    })
    .map(orderLocation => {
      // Mapea campos de primer nivel
      return {
        ...orderLocation,
        ratioAmount: Math.round((orderLocation.totalAmount / totalAmount) * 100)
      }
    })
    .filter(e => e.totalOrder)

  const resumen: IOrderResumen = {
    totalOrder: count,
    totalAmount,
    amount,
    totalPayment,
    totalCash,
    currency: ordersMapped[0]?.currency,
  }

  return {
    orders: ordersMapped,
    resumen,
  }
}

const mapToOrderFilter = (orders: IOrdersResponse[], filter: { [key: string]: string }): IOrdersResponse[] => {
  return orders
    .map(orderLocation => {
      return {
        ...orderLocation,
        orders: orderLocation.orders.map(item => {

          if (filter.reset) {
            return item
          }

          if (filter.all) {
            return {
              ...item,
              orders: item.orders.filter(e =>
                e.orderNumber.toLowerCase().includes((filter.all)?.toLowerCase() || '') ||
                `${e.customerHistory.phone}`.toLowerCase().includes((filter.all)?.toLowerCase() || '') ||
                e.shipping?.address1?.toLowerCase().includes((filter.all)?.toLowerCase() || '') ||
                e.shipping?.provinceName?.toLowerCase().includes((filter.all)?.toLowerCase() || '') || 
                e.warehouseName?.toLowerCase().includes((filter.all)?.toLowerCase() || '')
              )
            }
          }

          return {
            ...item,
            orders: item.orders.filter(e =>
              e.orderNumber.toLowerCase().includes((filter.orderNumber)?.toLowerCase() || '') &&
              `${e.customerHistory.phone}`.toLowerCase().includes((filter.phone)?.toLowerCase() || '') &&
              e.shipping?.address1?.toLowerCase().includes((filter.address)?.toLowerCase() || '') &&
              e.shipping?.provinceName?.toLowerCase().includes((filter.province)?.toLowerCase() || '') &&
              e.warehouseName?.toLowerCase().includes((filter.warehouseName)?.toLowerCase() || '')
            )
          }
        }),
      }
    })

}

const getProvinceList = (orders: IOrdersResponse[]) => {
  const provinceList: string[] = []

  orders.map(orderLocation => {
    orderLocation.orders.map(e => {
      provinceList.push(e.province)
    })
  })

  const unique = provinceList
    .filter(e => e !== 'null')
    .filter((elem, index, self) => index === self.indexOf(elem))
    .sort((a, b) => {
      if (a < b) { return -1 }
      if (a > b) { return 1 }

      return 0
    })

  return unique
}
